import React from 'react';
import { Row, Col, Nav, NavItem, NavLink } from 'reactstrap';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <Row className="align-items-center justify-content-xl-between">
        <Col xl="6">
          <div className="copyright text-center text-xl-left text-muted">
            © 2020{' '}
            <a
              className="font-weight-bold ml-1"
              href="http://www.searpent.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              Searpent
            </a>
          </div>
        </Col>

        <Col xl="6">
          <Nav className="nav-footer justify-content-center justify-content-xl-end">
            <NavItem>
              <NavLink
                href="/terms-and-conditions"
                rel="noopener noreferrer"
                target="_blank"
              >
                {t('general.terms_and_conditions')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="http://www.searpent.com"
                rel="noopener noreferrer"
                target="_blank"
              >
                {t('general.support')}
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
    </footer>
  );
}

export default Footer;
