import React, {
  useMemo,
  useCallback,
  useEffect,
  useState,
  createContext,
  useLayoutEffect
} from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import PropTypes from 'prop-types';
import _ from 'lodash';

const filterCacheContext = createContext();
filterCacheContext.displayName = 'FilterCache';

function parseLocation(location) {
  const key1 = location.pathname;
  const key2 = qs.parse(location.search).source;
  if (!key2) {
    return {};
  }
  const value = location.search;
  return {
    key: `${key1}#${key2}`,
    value
  };
}

export function FilterCacheProvider({ children }) {
  const [filterCache, setFilterCache] = useState({});
  const { location, replace, action } = useHistory();

  // cache route
  useEffect(() => {
    // console.log(`[routeCache] route changed by action: ${action}`);
    if (location?.state?.cache === true) {
      // console.log(
      //   `[routeCache] caching pathname: ${location.pathname} and search: ${location.search}`
      // );
      setFilterCache(prevState => {
        const parsedLocation = parseLocation(location);
        if (parsedLocation) {
          return {
            ...prevState,
            [parsedLocation.key]: parsedLocation.value
          };
        }
        return prevState;
      });
    }
  }, [location, action]);

  // apply cached route
  useLayoutEffect(() => {
    const { key } = parseLocation(location);
    const cacheApplied = location?.state?.cacheApplied;
    const cache = location?.state?.cache;
    if (
      filterCache[key] &&
      cacheApplied !== true &&
      cache !== true &&
      action !== 'REPLACE' &&
      !_.isEqual(qs.parse(filterCache[key]), qs.parse(location.search))
    ) {
      // console.log(`[routeCache] applying cache: ${filterCache[key]}`);
      replace({
        search: `${filterCache[key]}`,
        state: {
          cacheApplied: true
        }
      });
    }
  }, [location, filterCache, replace, action]);

  return (
    <filterCacheContext.Provider
      value={{
        filterCache
      }}
    >
      {children}
    </filterCacheContext.Provider>
  );
}

FilterCacheProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export const useFilterForm = ({ defaultValues, requiredValues } = {}) => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [reqValues] = useState(requiredValues);
  const {
    location: { search, state },
    push,
    replace
  } = useHistory();

  useLayoutEffect(() => {
    const newState = {
      ...defaultValues,
      ...qs.parse(search)
    };
    const { cacheApplied } = state || {};
    if (
      _.difference(requiredValues, Object.keys(qs.parse(search))).length > 0 &&
      cacheApplied !== true
    ) {
      // console.log(
      //   '[routeCache] missing required fields - applying default values'
      // );
      replace({
        search: `?${qs.stringify(newState)}`
      });
    }
  }, [defaultValues, replace, requiredValues, search, state]);

  useEffect(() => {
    setValues(qs.parse(search));
  }, [search]);

  useEffect(() => {
    const errs = {};
    const keys = Object.keys(values);
    keys.forEach(k => {
      if (reqValues.includes(k) && !values[k]) {
        errs[k] = 'required';
      }
    });
    setErrors(errs);
  }, [reqValues, values]);

  const setValue = useCallback((key, value) => {
    setValues(prevState => ({
      ...prevState,
      [key]: value
    }));
  }, []);

  const isDirty = !_.isEqual(qs.parse(search), values);

  const apply = () => {
    push({
      search: `?${qs.stringify(values)}`,
      state: {
        cache: true
      }
    });
  };

  return {
    values,
    isDirty,
    setValue,
    apply,
    errors
  };
};

export const useURLFilter = () => {
  const {
    location: { search }
  } = useHistory();
  const filter = useMemo(() => qs.parse(search), [search]);
  return {
    filter
  };
};
