import React from 'react';
import PropTypes from 'prop-types';
import { useAccount } from 'utils/useAccount';
import numberToPercentage from 'utils/numberToPercentage';

function FormattedPercentage({ value, showPercentage }) {
  const { account } = useAccount();
  const digits = account?.settings?.devMode === true ? 2 : 0;
  return (
    <>
      {numberToPercentage(value, digits)}
      {showPercentage === true ? '%' : null}
    </>
  );
}

FormattedPercentage.propTypes = {
  value: PropTypes.number.isRequired, // vakue 0 - 1
  showPercentage: PropTypes.bool
};

FormattedPercentage.defaultProps = {
  showPercentage: true
};

export default FormattedPercentage;
