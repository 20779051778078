import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { S3Image } from 'aws-amplify-react';
import classnames from 'classnames';

function ImageGalleryItem({
  imageUrl,
  imageKey,
  imageTitle,
  valid,
  s3,
  onClick,
  thumbnailPath,
  active
}) {
  let badgeColor = '';
  switch (valid) {
    case true:
      badgeColor = 'success';
      break;
    case false:
      badgeColor = 'danger';
      break;
    default:
      badgeColor = 'default';
      break;
  }

  const sanitizedThumbnailPath = thumbnailPath
    ? thumbnailPath.replace('public/', '')
    : null;

  return (
    <div
      className={classnames('gallery-thumbnail-image', {
        'gallery-thumbnail-image--active': active
      })}
    >
      {imageTitle && (
        <Badge color={badgeColor} className="image-thumbnail-title">
          {imageTitle}
        </Badge>
      )}

      {!s3 ? (
        <img alt="..." className="" src={imageUrl} />
      ) : (
        <S3Image
          key={sanitizedThumbnailPath ? null : imageKey}
          imgKey={sanitizedThumbnailPath ? null : imageKey}
          path={sanitizedThumbnailPath}
          onClick={() => onClick(imageKey)}
        />
      )}
    </div>
  );
}

ImageGalleryItem.propTypes = {
  imageUrl: PropTypes.string,
  imageKey: PropTypes.string,
  thumbnailPath: PropTypes.string,
  imageTitle: PropTypes.string.isRequired,
  valid: PropTypes.bool.isRequired,
  s3: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool
};

ImageGalleryItem.defaultProps = {
  imageUrl: null,
  imageKey: null,
  s3: false,
  active: false,
  thumbnailPath: ''
};

export default ImageGalleryItem;
