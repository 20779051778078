import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

function ItemNotFound({ showGoBackButton }) {
  const { t } = useTranslation();
  const { goBack } = useHistory();

  return (
    <div className="fullscreen-placeholder">
      <div className="d-flex flex-column">
        <h1>{t('general.item_does_not_exist')}</h1>
        {showGoBackButton && (
          <Button
            className="btn-icon btn-3"
            color="default"
            type="button"
            onClick={goBack}
          >
            <span className="btn-inner--text">{t('general.go_back')}</span>
          </Button>
        )}
      </div>
    </div>
  );
}

ItemNotFound.propTypes = {
  showGoBackButton: PropTypes.bool
};

ItemNotFound.defaultProps = {
  showGoBackButton: false
};

export default ItemNotFound;
