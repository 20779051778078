import React from 'react';
import { useTranslation } from 'react-i18next';

function ItemLoading() {
  const { t } = useTranslation();

  return (
    <div className="fullscreen-placeholder--loader header pb-6 pt-5 pt-md-8 bg-gradient-info">
      <div className="d-flex flex-column">
        <h1 className="text-white">{t('general.loading_data')}</h1>
      </div>
    </div>
  );
}

export default ItemLoading;
