function groupModelResults(modelResults, models) {
  const results = {};

  modelResults.forEach(mr => {
    const foundGroup = models.find(m => m.modelFamily === mr.modelFamily);
    const groupKey =
      !foundGroup || foundGroup.displayType === null
        ? 'generic'
        : foundGroup.displayType;
    if (results[groupKey] === undefined) {
      results[groupKey] = [];
    }
    results[groupKey].push(mr);
  });

  return results;
}

export default groupModelResults;
