import React from 'react';
import { Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Check from 'components/Check';
import FeedbackBadgeGroup from 'components/FeedbackBadgeGroup';
import CarColorBadge from 'components/CarColorBadge';
import formatTimestamp from 'utils/formatTimestamp';
import { compareByCreatedAtDesc } from 'utils/compareFunctions';
import { useTranslation } from 'react-i18next';

function InspectionsTable({ items }) {
  const { t } = useTranslation();
  const sorted = items.sort(compareByCreatedAtDesc);
  const filteredCaseIds = sorted.map(item => item.id);
  return (
    <Table className="align-items-center table-flush" responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col">{t('inspection.table.case_name')}</th>
          <th scope="col">{t('inspection.table.created_at')}</th>
          <th scope="col">{t('inspection.table.status')}</th>
          <th scope="col">{t('inspection.table.car')}</th>
          <th scope="col">{t('inspection.table.car_model')}</th>
          <th scope="col">{t('inspection.table.color')}</th>
          <th scope="col">{t('inspection.table.exif_summary')}</th>
          <th scope="col">{t('inspection.table.views')}</th>
          <th scope="col">{t('inspection.table.feedback')}</th>
        </tr>
      </thead>
      <tbody>
        {sorted.map(item => (
          <tr key={item.id}>
            <td>
              <Link
                to={{
                  pathname: `/inspections/${item.id}`,
                  state: {
                    filteredCaseIds
                  }
                }}
              >
                {item.name}
              </Link>
            </td>
            <td>{formatTimestamp(item.createdAt)}</td>
            <td>
              <Check checked={item.status} />
            </td>
            <td>
              <Check checked={item.isCar || false} />
            </td>
            <td>{item.carModel}</td>
            <td>
              <CarColorBadge color={item.color || ''} />
            </td>
            <td>{item.exifStatus || '-'}</td>
            <td>
              <Check checked={item?.views || false} />
            </td>
            <td>
              <FeedbackBadgeGroup feedback={item.feedback} />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

InspectionsTable.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      status: PropTypes.bool.isRequired,
      isCar: PropTypes.bool,
      color: PropTypes.string,
      exifStatus: PropTypes.string,
      views: PropTypes.bool
    })
  ).isRequired
};

export default InspectionsTable;
