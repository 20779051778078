import React from 'react';
import { Table, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import formatTimestamp from 'utils/formatTimestamp';
import { Storage } from 'aws-amplify';
import removePublicPrefix from 'utils/removePublicPrefix';
import getFilename from 'utils/getFilename';
import { compareByCreatedAtDesc } from 'utils/compareFunctions';
import { useTranslation } from 'react-i18next';

function ExportsTable({ items }) {
  const { t } = useTranslation();
  const downloadFile = async key => {
    const file = await Storage.get(removePublicPrefix(key), {
      download: true,
      level: 'public'
    });
    const filename = getFilename(key);
    const blob = new Blob([file.Body], {
      type: file.ContentType
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <Table className="align-items-center table-flush" responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col">{t('export.table.name')}</th>
          <th scope="col">{t('export.table.source')}</th>
          <th scope="col">{t('export.table.from')}</th>
          <th scope="col">{t('export.table.to')}</th>
          <th scope="col">{t('export.table.created_at')}</th>
          <th scope="col">{t('export.table.type')}</th>
          <th scope="col" className="d-flex justify-content-center">
            {t('export.table.download')}
          </th>
        </tr>
      </thead>
      <tbody>
        {items.sort(compareByCreatedAtDesc).map(item => (
          <tr key={item.id}>
            <td>{item.name}</td>
            <td>{item.source}</td>
            <td>{formatTimestamp(item.from)}</td>
            <td>{formatTimestamp(item.to)}</td>
            <td>{formatTimestamp(item.createdAt)}</td>
            <td>{item.exportType}</td>
            <td className="d-flex justify-content-center">
              <Button
                color="primary"
                size="sm"
                type="button"
                outline
                onClick={() => downloadFile(item.s3Object.key)}
              >
                <span className="btn-inner--icon mr-0">
                  <i className="fas fa-download" />
                </span>
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

ExportsTable.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      source: PropTypes.string.isRequired,
      from: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      exportType: PropTypes.string.isRequired,
      s3Object: PropTypes.shape({
        bucket: PropTypes.string.isRequired,
        key: PropTypes.string.isRequired,
        region: PropTypes.string.isRequired
      }).isRequired
    })
  ).isRequired
};

export default ExportsTable;
