import React from 'react';
import { Card, CardHeader, Container, Row } from 'reactstrap';
import ExportsHeader from 'components/Headers/ExportsHeader';
import ExportsTable from 'components/Tables/ExportsTable';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Loader from 'components/Loader';
import { useTranslation } from 'react-i18next';
import { useURLFilter } from 'utils/useFilter';
import addTimeToDates from 'utils/addTimeToDates';

const LIST_EXPORTS = gql`
  query listExports($filter: ListExportsInput!) {
    listExports(filter: $filter) {
      items {
        id
        name
        exportType
        createdAt
        source
        from
        to
        s3Object {
          key
          bucket
          region
        }
      }
    }
  }
`;

function Exports() {
  const { t } = useTranslation();
  const { filter } = useURLFilter();
  const { data, loading, error, refetch } = useQuery(LIST_EXPORTS, {
    variables: {
      filter: addTimeToDates(filter)
    }
  });

  if (error) return <h1>GQL error...</h1>;

  return (
    <>
      <ExportsHeader onExportAdded={refetch} />
      <Container className="mt--7" fluid>
        <Row className="mb-4">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0 text-capitalize">{t('general.exports')}</h3>
              </CardHeader>
              {loading && <Loader title="loading data" />}
              {data && <ExportsTable items={data.listExports.items} />}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default Exports;
