import React, { useState, useEffect } from 'react';
import { Card, Container, Row, Col } from 'reactstrap';
import InspectionDetailHeader from 'components/Headers/InspectionDetailHeader';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { useAccount } from 'utils/useAccount';
import groupModelResults from 'utils/groupModelResults';
import { useTranslation } from 'react-i18next';
import nextAndPreviousIndex from 'utils/nextAndPreviousIndex';
import ItemLoading from 'components/ItemLoading';
import ItemNotFound from 'components/ItemNotFound';
import ImageGallery from 'components/ImageGallery';
import GalleryDetail from 'components/GalleryDetail';
import ModalWithNavigation from 'components/ModalWithNavigation';
import ImageWithBoundingBoxes from 'components/ImageWithBoundingBoxes';

const GET_INSPECTION = gql`
  query getInspection($id: ID!) {
    getInspection(id: $id) {
      id
      name
      source
      feedback {
        casecheck
        exiferror
        modelerror
      }
      sessionStatus
      requiredFields {
        fieldId
        label
      }
      createdAt
      invitedAt
      confirmedAt
      recalcWindow
      status
      summary {
        iscar
        color {
          label
          score
        }
        model {
          label
          score
        }
        make {
          label
          score
        }
        allviews
        exifstatus
        viewChecklist {
          expected
          check
          count
        }
        viewSummary {
          check
          expected
          found
          photoId
        }
        exifSummary {
          key
          value
        }
      }
      summaryV2 {
        title
        rows {
          layout
          name
          label
          count
          score
          check
        }
      }
      photoSetup {
        photoId
        modelName
        verified
        label
        savedPhotoId
      }
      photos {
        id
        createdAt
        thumbnail {
          height
          width
          key
        }
        fullsize {
          height
          width
          key
        }
        modelResults {
          v1 {
            name
            modelFamily
            results {
              label
              score
              box {
                x1: X1
                x2: X2
                y1: Y1
                y2: Y2
              }
            }
          }
        }
      }
    }
  }
`;

function getCaseAggregationFromAlbum(albumSummary) {
  return {
    manafacturer: albumSummary.make,
    model: albumSummary.model,
    color: albumSummary.color,
    caseIsCar: albumSummary.iscar,
    allViews: albumSummary.allviews,
    exifSummary: albumSummary.exifstatus
  };
}

function filterAvailableModels(modelResults, availableModels) {
  return modelResults.filter(mr => availableModels.includes(mr.modelFamily));
}

function composeGallery(photoSetup, photos) {
  return photoSetup
    .map(ps => ({
      photoSet: ps,
      photo: photos.find(p => ps.savedPhotoId === p.id)
    }))
    .filter(i => i.photo);
}

function InspectionsDetail() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { account } = useAccount();
  const { loading, error, data } = useQuery(GET_INSPECTION, {
    variables: { id }
  });
  const [galleryIndex, setGalleryIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);

  // // after every gql query, set focusedPictureId
  useEffect(() => {
    if (data?.getInspection) {
      setGalleryIndex(0);
    }
  }, [data]);

  if (loading) return <ItemLoading />;
  if (error) return <h1>{t('general.error')}</h1>;
  if (data?.getInspection === null) return <ItemNotFound showGoBackButton />;

  const { getInspection: inspection } = data;
  const { photoSetup, photos } = inspection;
  const gallery = composeGallery(photoSetup, photos);
  const caseAggregation = inspection.summary
    ? getCaseAggregationFromAlbum(inspection.summary)
    : null;
  const focusedPhoto = gallery.length > 0 ? gallery[galleryIndex].photo : null;
  const availableModelFamilies = () => {
    const { loading: accountLoading } = account;
    if (!accountLoading) {
      return account.models.map(model => model.modelFamily);
    }
    return [];
  };
  const filteredModelresults = focusedPhoto
    ? filterAvailableModels(
        focusedPhoto.modelResults.v1,
        availableModelFamilies()
      )
    : [];
  const groupedResults =
    focusedPhoto && focusedPhoto.modelResults && focusedPhoto.modelResults.v1
      ? groupModelResults(filteredModelresults, account.models)
      : {};
  const { nextIndex, previousIndex } = nextAndPreviousIndex(
    galleryIndex,
    gallery.length
  );

  return (
    <>
      <div className="header pb-6 pt-5 pt-md-8 bg-gradient-info">
        <Container fluid>
          <InspectionDetailHeader
            id={id}
            name={inspection.name}
            source={inspection.source}
            requiredFields={inspection.requiredFields.map(rf => rf.label)}
            sessionStatus={inspection.sessionStatus}
            createdAt={inspection.createdAt}
            invitedAt={inspection.invitedAt}
            confirmedAt={inspection.confirmedAt}
            viewAggragation={inspection?.summary?.viewChecklist || []}
            caseAggregation={caseAggregation}
            summaryV2={inspection?.summaryV2}
          />
        </Container>
      </div>
      <Container className="mt--5" fluid>
        <Row className="mt-4">
          <Col>
            <Card className="p-3">
              {gallery.length === 0 && (
                <h2 className="text-center">
                  {t('general.no_data_to_display')}
                </h2>
              )}
              <ImageGallery
                gallery={gallery}
                selectedIndex={galleryIndex}
                handleClick={idx => setGalleryIndex(idx)}
              />
            </Card>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row className="mt-4">
          <Col>
            {focusedPhoto !== null && (
              <GalleryDetail
                photo={{
                  ...focusedPhoto,
                  path: focusedPhoto.fullsize.key.replace('/public', '')
                }}
                groupedResults={groupedResults}
                onPictureClick={() => setShowModal(true)}
              />
            )}
          </Col>
        </Row>
      </Container>
      {showModal && (
        <ModalWithNavigation
          showNavigation
          onLeftClick={
            previousIndex !== null ? () => setGalleryIndex(previousIndex) : null
          }
          onRightClick={
            nextIndex !== null ? () => setGalleryIndex(nextIndex) : null
          }
          onClose={() => setShowModal(false)}
          title={`${focusedPhoto.id} (${galleryIndex + 1}/${photos.length})`}
        >
          <ImageWithBoundingBoxes
            photo={focusedPhoto.fullsize}
            modelResults={filteredModelresults}
          />
        </ModalWithNavigation>
      )}
    </>
  );
}

export default InspectionsDetail;
