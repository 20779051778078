import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import picture from 'assets/img/brand/SEARPENT_animated_150px.gif';

function Loader({ title, dark }) {
  return (
    <div
      className={classnames({
        dimmer__loader: !dark,
        'dimmer__loader--dark': dark
      })}
    >
      <div className="dimmer__loader__content">
        <img src={picture} alt="loading" style={{ width: '50px' }} />
        <h5>{title}</h5>
      </div>
    </div>
  );
}

Loader.propTypes = {
  title: PropTypes.string,
  dark: PropTypes.bool
};

Loader.defaultProps = {
  title: '',
  dark: false
};

export default Loader;
