import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Form
} from 'reactstrap';
import gql from 'graphql-tag';
import moment from 'moment';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { useAccount } from 'utils/useAccount';
import picture from 'assets/img/brand/SEARPENT_animated_150px.gif';
import { useTranslation } from 'react-i18next';

function sanitizeValues(values) {
  const v = JSON.parse(JSON.stringify(values));
  if (v.from) {
    v.from = moment(v.from)
      .utc()
      .toISOString();
  }
  if (v.to) {
    v.to = moment(v.to)
      .utc()
      .toISOString();
  }
  return v;
}

const CREATE_EXPORT = gql`
  mutation createExport($input: CreateExportInput!) {
    createExport(input: $input) {
      id
      name
    }
  }
`;

function AddExportButton({ onExportAdded }) {
  const { t } = useTranslation();
  const { sources } = useAccount();
  const exportSources =
    sources && sources.filter(source => source.hasExports === true);

  // modal controls
  const [modalOpen, setModalOpen] = useState(false);

  // input controls
  const defaultValues = {
    source:
      exportSources && exportSources.length > 0
        ? exportSources[0].filterName
        : '',
    from: '',
    to: '',
    name: ''
  };

  const [values, setValues] = useState(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const [, setCreateExportError] = useState(null);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleExportAdded = () => {
    onExportAdded();
  };

  const resetValues = () => {
    setValues(defaultValues);
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    resetValues();
  };

  const [createExport] = useMutation(CREATE_EXPORT);

  const handleOnSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await createExport({
        variables: { input: sanitizeValues(values) }
      });
      setIsLoading(false);
      setCreateExportError(null);
      setModalOpen(false);
      toast.success(t('export.create_export_success'));
      handleExportAdded();
    } catch (error) {
      setIsLoading(false);
      setCreateExportError(error);
      toast.error(t('export.create_export_failure', { error }));
    }
  };

  return (
    <>
      <Button color="success" onClick={toggleModal}>
        {t('export.new_export')}
      </Button>
      {modalOpen && (
        <Modal
          className="modal-dialog-centered"
          isOpen={modalOpen}
          toggle={toggleModal}
          autoFocus={false}
        >
          <Form onSubmit={handleOnSubmit}>
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {t('export.create_export')}
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={toggleModal}
              >
                <span aria-hidden>×</span>
              </button>
            </div>
            <div className="modal-body">
              <Row>
                <Col>
                  <FormGroup>
                    <Label className="form-control-Label" htmlFor="name">
                      {t('export.name')}
                    </Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      onChange={handleInputChange}
                      value={values.name}
                      autoFocus
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label className="form-control-Label" htmlFor="source">
                      {t('export.source')}
                    </Label>
                    <Input
                      type="select"
                      name="source"
                      id="source"
                      onChange={handleInputChange}
                      value={values.source}
                    >
                      {exportSources &&
                        exportSources.map(exportSource => (
                          <option value={exportSource.filterName}>
                            {exportSource.name}
                          </option>
                        ))}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label className="form-control-Label" htmlFor="from">
                      {t('export.from')}
                    </Label>
                    <Input
                      className="from"
                      id="from"
                      name="from"
                      type="date"
                      onChange={handleInputChange}
                      value={values.from}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label className="form-control-Label" htmlFor="from">
                      {t('export.to')}
                    </Label>
                    <Input
                      className="to"
                      id="to"
                      name="to"
                      type="date"
                      onChange={handleInputChange}
                      value={values.to}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div className="modal-footer">
              <Button
                color="secondary"
                data-dismiss="modal"
                type="button"
                onClick={toggleModal}
              >
                {t('general.cancel')}
              </Button>
              <Button
                color="success"
                disabled={isLoading}
                type="button"
                onClick={handleOnSubmit}
              >
                {isLoading && (
                  <img
                    src={picture}
                    alt="loading"
                    style={{ height: '2rem' }}
                    className="mr-2"
                  />
                )}
                {!isLoading
                  ? t('export.create_export')
                  : t('export.creating_export')}
              </Button>
            </div>
          </Form>
        </Modal>
      )}
    </>
  );
}

AddExportButton.propTypes = {
  onExportAdded: PropTypes.func.isRequired
};

export default AddExportButton;
