function getBreadcrumbFromBreadcrumbTokens(breadcrumbTokens, index) {
  const breadcrumb = breadcrumbTokens[index];
  if (!breadcrumb && index !== 0) {
    throw new Error(`can't find breadcrumb for item at index ${index}`);
  }

  // if empty string, than redirect to index
  if (breadcrumb === '') {
    return {
      path: '/',
      faIcon: 'fas fa-home',
      active: breadcrumbTokens.length - 1 === index // if item is last of tokens, than 'active'
    };
  }

  // if some string, take all previous tokens from path and create pathname
  let pathname = '';
  for (let i = 1; i <= index; i += 1) {
    pathname += `/${breadcrumbTokens[i]}`;
  }
  return {
    path: pathname,
    name: breadcrumbTokens[index],
    active: breadcrumbTokens.length - 1 === index // if item is last of tokens, than 'active'
  };
}

function pathnameToBreadcrumbs(pathname) {
  const tokens = pathname.split('/');

  if (tokens[tokens.length - 1] === '') {
    tokens.pop();
  }

  if (tokens.length === 2 && tokens[0] === tokens[1]) {
    tokens.shift();
  }

  const breadcrumbs = [];
  for (let i = 0; i < tokens.length; i += 1) {
    const breadcrumb = getBreadcrumbFromBreadcrumbTokens(tokens, i);
    breadcrumbs.push(breadcrumb);
  }
  return breadcrumbs;
}

export default pathnameToBreadcrumbs;
