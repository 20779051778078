import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  Nav,
  Row,
  CardBody,
  Input,
  FormGroup
} from 'reactstrap';
import Chart from 'chart.js';
import { Line } from 'react-chartjs-2';
import {
  chartOptions,
  parseOptions,
  metricsChartOptions
} from 'variables/charts';
import Loader from 'components/Loader';
import DimmerArea from 'components/DimmerArea';
import { useTranslation } from 'react-i18next';

function MetricsChart({
  graphData,
  statisticOptions,
  selectedStatisticOption,
  periodOptions,
  timeRangeOptions,
  handleInputChange,
  isLoading,
  isError
}) {
  const { t } = useTranslation();

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const unit = selectedStatisticOption !== 'SampleCount' ? 'ms' : '';

  return (
    <Card className="bg-gradient-default shadow">
      {isLoading && <Loader title={t('general.loading_data')} dark />}
      {(isError || !graphData) && (
        <DimmerArea>
          <div className="w-100 h-100 d-flex justify-content-center align-items-center">
            <h3 className="text-white">{t('general.no_data_to_display')}</h3>
          </div>
        </DimmerArea>
      )}
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <Nav className="justify-content-end" pills>
              <FormGroup className="mr-4 zi-5">
                <Input
                  className="form-control-alternative"
                  type="select"
                  name="stat"
                  id="stat"
                  defaultValue={statisticOptions[0].value}
                  onChange={handleInputChange}
                >
                  {statisticOptions.map(s => (
                    <option value={s.value} key={s.value}>
                      {t(`metrics.stat_options.${s.label}`)}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup className="mr-4 zi-5">
                <Input
                  className="form-control-alternative"
                  type="select"
                  name="period"
                  id="period"
                  defaultValue={periodOptions[0].value}
                  onChange={handleInputChange}
                >
                  {periodOptions.map(s => (
                    <option value={s.value} key={s.value}>
                      {t(`metrics.period_options.${s.label}`)}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup className="zi-5">
                <Input
                  className="form-control-alternative"
                  type="select"
                  name="timeRange"
                  id="timeRange"
                  defaultValue={timeRangeOptions[0].value}
                  onChange={handleInputChange}
                >
                  {timeRangeOptions.map(s => (
                    <option value={s.value} key={s.value}>
                      {t(`metrics.time_range_options.${s.label}`)}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Nav>
          </div>
        </Row>
      </CardHeader>
      <CardBody>
        <div className="chart">
          <Line
            data={graphData || {}}
            options={metricsChartOptions({
              unit
            })}
          />
        </div>
      </CardBody>
    </Card>
  );
}

MetricsChart.propTypes = {
  graphData: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        borderColor: PropTypes.string,
        data: PropTypes.arrayOf(PropTypes.number).isRequired
      })
    )
  }),
  selectedStatisticOption: PropTypes.string.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool,
  statisticOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  periodOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  timeRangeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired
};

MetricsChart.defaultProps = {
  graphData: null,
  isError: false
};

export default MetricsChart;
