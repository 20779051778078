import React from 'react';
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Input,
  Label
} from 'reactstrap';
import { useAccount } from 'utils/useAccount';
import { useTranslation } from 'react-i18next';
import { useFilterForm } from 'utils/useFilter';
import moment from 'moment';

function CaseFilter() {
  const { t } = useTranslation();
  const { sources } = useAccount();
  const { values, setValue, apply, isDirty, errors } = useFilterForm({
    defaultValues: {
      from: moment()
        .subtract(7, 'days')
        .startOf('day')
        .format('YYYY-MM-DD'),
      to: moment()
        .endOf('day')
        .format('YYYY-MM-DD')
    },
    requiredValues: ['source', 'from', 'to']
  });

  const handleInputChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    setValue(name, value);
  };

  const onSubmit = async e => {
    e.preventDefault();
    apply();
  };

  return (
    <Card className="card-stats mb-4 mb-xl-0 bg-secondary">
      <CardBody>
        <Row>
          <Col>
            <h4>{t('case.case_filter')}</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label className="form-control-Label" htmlFor="source">
                {t('filter.source')}
              </Label>
              <Input
                id="source"
                name="source"
                type="select"
                className="form-control-alternative"
                value={values.source || ''}
                onChange={handleInputChange}
              >
                <option value="">-</option>
                {sources &&
                  sources
                    .filter(s => !s.hasInspections)
                    .map(s => (
                      <option value={s.filterName} key={s.filterName}>
                        {s.name}
                      </option>
                    ))}
              </Input>
              <div className="form-control-feedback pt-1 text-left">
                {errors.source}
              </div>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label className="form-control-Label" htmlFor="from">
                {t('filter.created_from')}
              </Label>
              <Input
                id="from"
                name="from"
                type="date"
                className="form-control-alternative"
                value={values.from || ''}
                max={new Date().toISOString().substring(0, 10)}
                onChange={handleInputChange}
              />
              <div className="form-control-feedback pt-1 text-left">
                {errors.from}
              </div>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label className="form-control-Label" htmlFor="to">
                {t('filter.created_to')}
              </Label>
              <Input
                id="to"
                name="to"
                type="date"
                className="form-control-alternative"
                value={values.to || ''}
                max={new Date().toISOString().substring(0, 10)}
                onChange={handleInputChange}
              />
              <div className="form-control-feedback pt-1 text-left">
                {errors.to}
              </div>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label className="form-control-Label" htmlFor="status">
                {t('filter.status')}
              </Label>
              <Input
                id="status"
                name="status"
                type="select"
                className="form-control-alternative"
                value={values.status || ''}
                onChange={handleInputChange}
              >
                <option value="">-</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Input>
              <div className="form-control-feedback pt-1 text-left">
                {errors.status}
              </div>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label className="form-control-Label" htmlFor="isChecked">
                {t('filter.is_checked')}
              </Label>
              <Input
                id="isChecked"
                name="isChecked"
                type="select"
                className="form-control-alternative"
                value={values.isChecked || ''}
                onChange={handleInputChange}
              >
                <option value="">-</option>
                <option value="true">{t('general.yes')}</option>
                <option value="false">{t('general.no')}</option>
              </Input>
              <div className="form-control-feedback pt-1 text-left">
                {errors.isChecked}
              </div>
            </FormGroup>
          </Col>
          <Col className="d-flex justify-content-end align-items-center">
            <Button
              color="primary"
              type="submit"
              onClick={onSubmit}
              disabled={!(isDirty && Object.keys(errors).length === 0)}
            >
              {t('filter.apply')}
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default CaseFilter;
