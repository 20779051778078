import { Storage } from 'aws-amplify';
import removePublicPrefix from 'utils/removePublicPrefix';

function labelAndTextFromResultText(resultText) {
  if (!resultText) {
    return {};
  }
  const parsedResultText = JSON.parse(resultText);
  const { label } = parsedResultText[0];
  const { text } = parsedResultText[0];
  return { label, text };
}

function modelResultsToRegions(modelResults) {
  return modelResults.map(r => {
    const { label, text } = labelAndTextFromResultText(r.text);
    return {
      id: r.id,
      type: 'box',
      visible: true,
      cls: label,
      highlighted: false,
      groupHighlighted: false,
      x: r.box.x1,
      y: r.box.y1,
      w: r.box.x2 - r.box.x1,
      h: r.box.y2 - r.box.y1,
      groupId: r.groupId,
      text,
      score: r.score
    };
  });
}

// lastUpdatedModelResults choose either modelResults or editedModelResults
function lastUpdatedModelResults(photo) {
  if (photo.editedModelResults && photo.editedModelResults.v1) {
    return photo.editedModelResults.v1;
  }
  if (photo.modelResults && photo.modelResults.v1) {
    return photo.modelResults.v1;
  }
  return [];
}

function getExtractionEngineResults(
  modelResults = [],
  extractionEngineClasses = []
) {
  const extractionEngineResults = modelResults.filter(mr =>
    extractionEngineClasses.includes(mr.name)
  );
  if (extractionEngineResults.length > 0) {
    return extractionEngineResults[0].results;
  }
  return [];
}

async function photosToImages(photos, extractionEngineClasses) {
  return Promise.all(
    photos.map(async photo => {
      const fullsizeSignedURL = await Storage.get(
        removePublicPrefix(photo.fullsize.key)
      );
      const thumbnailSignedURL = await Storage.get(
        removePublicPrefix(photo.thumbnail.key)
      );
      const modelResults = lastUpdatedModelResults(photo);
      const extractionEngineResults = getExtractionEngineResults(
        modelResults,
        extractionEngineClasses
      );

      return {
        id: photo.id,
        src: fullsizeSignedURL,
        thumbnail: thumbnailSignedURL,
        originalSrc: photo.fullsize.key,
        originalThumbnail: photo.thumbnail.key,
        name: photo.fullsize.key,
        regions: modelResultsToRegions(extractionEngineResults),
        metadata: photo.metadata
      };
    })
  );
}

export default photosToImages;
