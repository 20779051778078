import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

function Check({ checked }) {
  const iconClassName = checked ? 'fas fa-check' : 'fas fa-times';
  const colorClassName = checked ? 'isActive' : 'notActive';

  return <i className={classnames(iconClassName, colorClassName)} />;
}

Check.propTypes = {
  checked: PropTypes.bool.isRequired
};

export default Check;
