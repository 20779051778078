import React, { useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from 'reactstrap';
import { forgotPasswordSubmit } from 'utils/auth';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Link, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { useTranslation } from 'react-i18next';

function NewPassword(props) {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { username: userName } = qs.parse(search);

  const defaultValues = {
    username: userName,
    code: '',
    password: ''
  };

  const [values, setValues] = useState(defaultValues);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const resetValues = () => {
    setValues(defaultValues);
  };

  const handleOnSubmit = async e => {
    e.preventDefault();
    const { username, code, password } = values;
    const { history } = props;

    setIsLoading(true);
    try {
      await forgotPasswordSubmit(username, code, password);
      setIsLoading(false);
      toast.success(t('password_change.password_change_success'));
      history.push('/login');
    } catch (error) {
      toast.error(t('password_change.password_change_failure', { error }));
      setIsLoading(false);
      resetValues();
    }
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>{t('password_change.set_new_password')}</small>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-key-25" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    id="password"
                    name="password"
                    type="password"
                    value={values.password}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    id="code"
                    name="code"
                    placeholder={t(
                      'password_change.verification_code_placeholder'
                    )}
                    type="text"
                    value={values.code}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button
                  className="my-4"
                  color="primary"
                  type="submit"
                  onClick={handleOnSubmit}
                  disabled={isLoading}
                >
                  {isLoading
                    ? t('password_change.sending_email')
                    : t('password_change.set_new_password')}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col className="text-right">
            <Link
              to={{
                pathname: `/register`
              }}
            >
              <small>{t('password_change.create_new_account')}</small>
            </Link>
          </Col>
        </Row>
      </Col>
    </>
  );
}

NewPassword.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default NewPassword;
