import React from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';

function PublicImage() {
  const { search } = useLocation();
  const { src } = qs.parse(search);
  const decodedSrc = atob(src);
  return (
    <div className="public-image-wrapper">
      <img src={decodedSrc} alt="public-version" />
    </div>
  );
}

export default PublicImage;
