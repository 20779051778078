import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import AdminFooter from 'components/Footers/AdminFooter';
import Sidebar from 'components/Sidebar/Sidebar';
import routes from 'routes';
import {
  Dashboard,
  Cases,
  CasesDetail,
  Exports,
  Metrics,
  Inspections,
  InspectionsDetail,
  Settings
} from 'views';

function Admin() {
  return (
    <>
      <Sidebar
        routes={routes}
        logo={{
          innerLink: '/',
          // eslint-disable-next-line global-require
          imgSrc: require('assets/img/brand/argon-react.png'),
          imgAlt: '...'
        }}
      />
      <div className="main-content">
        <AdminNavbar />
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/cases" component={Cases} />
          <Route exact path="/cases/:id" component={CasesDetail} />
          <Route exact path="/inspections" component={Inspections} />
          <Route exact path="/inspections/:id" component={InspectionsDetail} />
          <Route exact path="/exports" component={Exports} />
          <Route exact path="/metrics" component={Metrics} />
          <Route exact path="/settings" component={Settings} />
        </Switch>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
}

export default Admin;
