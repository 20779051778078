// eslint-disable-next-line import/prefer-default-export
export function colorStringToHex(colorString) {
  switch (colorString) {
    case 'beige/cream':
      return {
        backgroundColor: 'C8AD7F',
        textColor: '000000'
      };
    case 'white':
      return {
        backgroundColor: 'FFFFFF',
        textColor: '000000'
      };
    case 'blue':
      return {
        backgroundColor: '0000FF',
        textColor: 'FFFFFF'
      };
    case 'silver/grey':
      return {
        backgroundColor: 'C0C0C0',
        textColor: '000000'
      };
    case 'green':
      return {
        backgroundColor: '008000',
        textColor: 'FFFFFF'
      };
    case 'black':
      return {
        backgroundColor: '000000',
        textColor: 'FFFFFF'
      };
    case 'red':
      return {
        backgroundColor: 'FF0000',
        textColor: 'FFFFFF'
      };
    case 'yellow':
      return {
        backgroundColor: 'FFFF00',
        textColor: '000000'
      };
    default:
      return {
        backgroundColor: 'C0C0C0',
        textColor: '000000'
      };
  }
}
