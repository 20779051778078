import React, { useEffect, useState, useCallback } from 'react';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  UncontrolledAlert
} from 'reactstrap';
import { login, confirmLoginMFA } from 'utils/auth';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const defaultValues = {
  username: '',
  password: '',
  mfaCode: ''
};

function Login(props) {
  const { t } = useTranslation();
  const [values, setValues] = useState(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [challengeName, setChallengeName] = useState('');
  const [user, setUser] = useState(null);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const resetValues = useCallback(() => {
    setValues(defaultValues);
  }, []);

  const confirmSignUp = useCallback(
    async code => {
      const { history } = props;

      setIsLoading(true);
      try {
        await confirmLoginMFA(user, code);
        setIsLoading(false);
        setLoginError(null);
        history.push('/');
        toast.success(t('login.login_success'));
      } catch (error) {
        toast.error(t('login.login_failure', { error }));
        setIsLoading(false);
        setLoginError(error);
      }
    },
    [props, t, user]
  );

  useEffect(() => {
    if (values?.mfaCode && values.mfaCode.length === 6) {
      confirmSignUp(values.mfaCode);
    }
  }, [confirmSignUp, values]);

  const handleOnSubmit = async e => {
    e.preventDefault();
    const { username, password } = values;
    const { history } = props;

    setIsLoading(true);
    try {
      const loggedUser = await login(username, password);
      // MFA required
      if (loggedUser?.challengeName) {
        setChallengeName(loggedUser?.challengeName);
        setUser(loggedUser);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setLoginError(null);
        history.push('/');
        toast.success(t('login.login_success'));
      }
    } catch (error) {
      toast.error(t('login.login_failure', { error }));
      setIsLoading(false);
      setLoginError(error);
      resetValues();
    }
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            {loginError && (
              <UncontrolledAlert color="danger" fade={false}>
                <span className="alert-inner--icon">
                  <i className="ni ni-circle-08" />
                </span>{' '}
                {t('login.failed_to_log_in')}
              </UncontrolledAlert>
            )}

            <div className="text-center text-muted mb-4">
              <small>{t('login.sign_in')}</small>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-circle-08" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    id="username"
                    name="username"
                    placeholder={t('login.username_placeholder')}
                    type="text"
                    value={values.username}
                    onChange={handleInputChange}
                    disabled={challengeName}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="new-password"
                    placeholder={t('login.password_placeholder')}
                    value={values.password}
                    onChange={handleInputChange}
                    disabled={challengeName}
                  />
                </InputGroup>
              </FormGroup>
              {challengeName === 'SOFTWARE_TOKEN_MFA' ? (
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-circle-08" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      id="mfaCode"
                      name="mfaCode"
                      placeholder={t('login.mfa_code')}
                      type="text"
                      value={values.mfaCode}
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                </FormGroup>
              ) : null}

              <div className="text-center">
                <Button
                  className="my-4"
                  color="primary"
                  type="submit"
                  onClick={handleOnSubmit}
                  disabled={isLoading}
                >
                  {isLoading ? t('login.logging_in') : t('login.log_in')}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <Link
              to={{
                pathname: `/forgot-password`
              }}
            >
              <small>{t('login.forgot_password')}</small>
            </Link>
          </Col>
          <Col className="text-right" xs="6">
            <Link
              to={{
                pathname: `/register`
              }}
            >
              <small>{t('login.create_new_account')}</small>
            </Link>
          </Col>
        </Row>
      </Col>
    </>
  );
}

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default Login;
