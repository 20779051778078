function validPhotoSummary(expected, found) {
  if (expected.toLowerCase() === 'n/a') {
    return undefined;
  }

  if (expected.toLowerCase() === found.toLowerCase()) {
    return true;
  }

  return false;
}

export default validPhotoSummary;
