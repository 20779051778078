const colors = [
  `255,252,0`,
  `255,8,232`,
  `65,253,254`,
  `0,255,0`,
  `102,255,0`,
  `255,0,0`,
  `255,192,0`,
  `102,255,0`,
  `255,255,0`
];

export default colors;
