import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import AddExportButton from 'components/AddExportButton';
import ExportFilter from 'components/ExportFilter';

function ExportsHeader({ onExportAdded }) {
  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            <Row className="pb-4">
              <Col>
                <AddExportButton onExportAdded={onExportAdded} />
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <ExportFilter />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

ExportsHeader.propTypes = {
  onExportAdded: PropTypes.func.isRequired
};

export default ExportsHeader;
