function icon(displayType) {
  switch (displayType) {
    case 'car':
      return 'fas fa-car';
    case 'view':
      return 'far fa-eye';
    case 'generic':
      return 'fas fa-bars';
    default:
      return 'fas fa-bars';
  }
}

export default icon;
