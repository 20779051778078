function modelResultsToTable(modelResults) {
  const results = modelResults.reduce(
    (prev, curr) => [...prev, ...curr.results],
    []
  );

  return results;
}

export default modelResultsToTable;
