import React, { useEffect, useRef } from 'react';
import { HotKeys } from 'react-hotkeys';
import { Modal } from 'reactstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const keyMap = {
  MOVE_LEFT: 'left',
  MOVE_RIGHT: 'right'
};

function ModalWithNavigation({
  showNavigation,
  onLeftClick,
  onRightClick,
  onClose,
  children,
  title,
  postTitle
}) {
  const headerEl = useRef(null);

  useEffect(() => {
    headerEl.current.focus();
  }, []);

  const hotKeysHandlers = {
    MOVE_LEFT: () => {
      if (onLeftClick) {
        onLeftClick();
      }
    },
    MOVE_RIGHT: () => {
      if (onRightClick) {
        onRightClick();
      }
    }
  };

  return (
    <HotKeys keyMap={keyMap} handlers={hotKeysHandlers} allowChanges>
      <Modal
        className="modal-dialog-centered modal--fullscreen max-width-90vw"
        isOpen
        toggle={onClose}
      >
        <div className="modal-header" ref={headerEl}>
          <h5
            className="modal-title text-capitalize mr-3"
            id="exampleModalLabel"
          >
            {title}
          </h5>
          <h5 className="modal-title posttitle" id="exampleModalLabel">
            {postTitle}
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={onClose}
          >
            <span aria-hidden>×</span>
          </button>
        </div>
        {children}
        <div className="modal-footer" />
        {showNavigation && (
          <div className="modal-navigation">
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div className="modal-navigation__left" onClick={onLeftClick}>
              <i
                className={classnames(
                  'fas fa-chevron-left modal-navigation__chevron',
                  {
                    'modal-navigation__chevron--disabled': !onLeftClick
                  }
                )}
              />
            </div>
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div className="modal-navigation__right" onClick={onRightClick}>
              <i
                className={classnames(
                  'fas fa-chevron-right modal-navigation__chevron',
                  {
                    'modal-navigation__chevron--disabled': !onRightClick
                  }
                )}
              />
            </div>
          </div>
        )}
      </Modal>
    </HotKeys>
  );
}

ModalWithNavigation.propTypes = {
  showNavigation: PropTypes.bool,
  onLeftClick: PropTypes.func,
  onRightClick: PropTypes.func,
  onClose: PropTypes.func,
  title: PropTypes.string,
  postTitle: PropTypes.string,
  children: PropTypes.element.isRequired
};

ModalWithNavigation.defaultProps = {
  showNavigation: true,
  onLeftClick: null,
  onRightClick: null,
  onClose: null,
  title: null,
  postTitle: null
};

export default ModalWithNavigation;
