import React, { useState, useEffect } from 'react';
import { Card, Container, Row, Col, Button } from 'reactstrap';
import CaseDetailHeader from 'components/Headers/CaseDetailHeader';
import { Link, useParams } from 'react-router-dom';
import ImageGalleryItem from 'components/ImageGalleryItem';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { S3Image } from 'aws-amplify-react';
import { useAccount } from 'utils/useAccount';
import modelResultsToTable from 'utils/modelResultsToTable';
import ModelResultsTable from 'components/ModelResultsTable';
import validPhotoSummary from 'utils/validPhotoSummary';
import groupModelResults from 'utils/groupModelResults';
import Dropzone from 'components/Dropzone';
import { compareByCreatedAt } from 'utils/compareFunctions';
import ExifDataTable from 'components/ExifDataTable';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ItemNotFound from 'components/ItemNotFound';
import ModalWithNavigation from 'components/ModalWithNavigation';
import { Storage } from 'aws-amplify';
import ImageWithHighlightedArea from 'components/ImageWithHighlightedArea';
import modelResultsToLabeledBoundingBoxes from 'utils/modelResultsToLabeledBoundingBoxes';
import stringToColor from 'utils/stringToColor';
import FormattedPercentage from 'components/FormattedPercentage';
import icon from 'utils/modelDisplayTypeIcons';
import ItemLoading from 'components/ItemLoading';
import hasRegionsForAnnotation from 'utils/hasRegionsForAnnotation';

function nextAndPreviousIndex(currentIndex, nOfItems) {
  let nextIndex = null;
  let previousIndex = null;

  if (Number.isNaN(currentIndex)) {
    return {
      nextIndex,
      previousIndex
    };
  }

  // next index
  if (currentIndex + 1 < nOfItems) {
    nextIndex = currentIndex + 1;
  }

  // previous index
  if (currentIndex - 1 >= 0) {
    previousIndex = currentIndex - 1;
  }

  return {
    nextIndex,
    previousIndex
  };
}

const GET_ALBUM_DETAIL = gql`
  query getAlbum($id: ID!) {
    getAlbum(id: $id) {
      id
      source
      name
      feedback {
        casecheck
        exiferror
        modelerror
      }
      status
      summary {
        iscar
        color {
          label
          score
        }
        model {
          label
          score
        }
        make {
          label
          score
        }
        allviews
        exifstatus
        viewChecklist {
          expected
          check
          count
        }
        viewSummary {
          check
          expected
          found
          photoId
        }
        exifSummary {
          key
          value
        }
      }
      summaryV2 {
        title
        rows {
          layout
          name
          label
          count
          score
          check
        }
      }
      photos {
        id
        createdAt
        thumbnail {
          height
          width
          key
        }
        fullsize {
          height
          width
          key
        }
        modelResults {
          v1 {
            name
            modelFamily
            results {
              label
              score
              box {
                x1: X1
                x2: X2
                y1: Y1
                y2: Y2
              }
              groupId
              text
            }
          }
        }
        exifData {
          v1 {
            key
            value
          }
        }
      }
    }
  }
`;

const UPDATE_FEEDBACK = gql`
  mutation updateAlbumFeedback(
    $id: ID!
    $source: String!
    $casecheck: Boolean!
    $exiferror: Boolean!
    $modelerror: Boolean!
  ) {
    updateAlbumFeedback(
      input: {
        id: $id
        source: $source
        modelerror: $modelerror
        casecheck: $casecheck
        exiferror: $exiferror
      }
    ) {
      id
      feedback {
        casecheck
        exiferror
        modelerror
      }
    }
  }
`;

function getCaseAggregationFromAlbum(albumSummary) {
  return {
    manafacturer: albumSummary.make,
    model: albumSummary.model,
    color: albumSummary.color,
    caseIsCar: albumSummary.iscar,
    allViews: albumSummary.allviews,
    exifSummary: albumSummary.exifstatus
  };
}

function filterAvailableModels(modelResults, availableModels) {
  return modelResults.filter(mr => availableModels.includes(mr.modelFamily));
}

function modelResultsToHighlightedAreas(modelResults) {
  const bboxes = modelResultsToLabeledBoundingBoxes(modelResults);
  const highlightedAreas = bboxes
    ? bboxes.map(bbox => ({
        id: `${bbox.model}:${bbox.label}`,
        name: `${bbox.label}`,
        percentage: bbox.score,
        shape: 'rect',
        relativeCoords:
          bbox && bbox.box
            ? [bbox.box.x1, bbox.box.y1, bbox.box.x2, bbox.box.y2]
            : null,
        preFillColor: `rgba(${stringToColor(
          `${bbox.model}:${bbox.label}`
        )}, 0.5)`,
        fillColor: `rgba(${stringToColor(
          `${bbox.model}:${bbox.label}`
        )}, 0.75)`,
        strokeColor: `rgba(${stringToColor(`${bbox.model}:${bbox.label}`)})`,
        lineWidth: 1,
        enabled: false
      }))
    : [];
  return highlightedAreas;
}

function CasesDetail() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { account, sources } = useAccount();
  const { loading, error, data, refetch } = useQuery(GET_ALBUM_DETAIL, {
    variables: { id }
  });
  const [updateFeedback, { loading: isFeedbackUpdating }] = useMutation(
    UPDATE_FEEDBACK
  );
  const [focusedPhotoIndex, setFocusedPhotoIndex] = useState(null);
  const [highlightedAreas, setHighlightedAreas] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [focusedImageUrl, setFocusedImageUrl] = useState(null);

  const album = data ? data.getAlbum : null;
  const photos = album ? album?.photos : [];
  const photoSummaries =
    album && album.summary && album.summary.viewSummary
      ? album.summary.viewSummary
      : [];
  const focusedPhoto =
    focusedPhotoIndex !== null && photos.length > 0
      ? photos[focusedPhotoIndex]
      : null;
  const { nextIndex, previousIndex } = nextAndPreviousIndex(
    focusedPhotoIndex,
    photos.length
  );

  const isAnnotable = hasRegionsForAnnotation(album, sources);

  // after every gql query, set focusedPictureId
  useEffect(() => {
    if (album && photos && photos.length > 0) {
      setFocusedPhotoIndex(0);
    }
  }, [album, photos]);

  useEffect(() => {
    if (focusedPhotoIndex !== null && !Number.isNaN(focusedPhotoIndex)) {
      // query a calculate modelResults
      const modelResults =
        data?.getAlbum?.photos[focusedPhotoIndex]?.modelResults.v1;
      if (modelResults && modelResults.length > 0) {
        const newHighlightedAreas =
          modelResultsToHighlightedAreas(modelResults) || [];
        setHighlightedAreas(newHighlightedAreas);
      } else {
        setHighlightedAreas([]);
      }
      // load s3 image url
      const k = data?.getAlbum?.photos[focusedPhotoIndex]?.fullsize.key;
      if (k) {
        Storage.get(k.replace('public/', ''))
          .then(url => setFocusedImageUrl(url))
          // eslint-disable-next-line no-console
          .catch(e => console.error(e));
      }
    }
  }, [focusedPhotoIndex, data]);

  const handleHighlightedAreaToggle = areaId => {
    const updatedAreas = highlightedAreas.map(area => ({
      ...area,
      enabled: area.id === areaId ? !area.enabled : area.enabled
    }));
    setHighlightedAreas(updatedAreas);
  };

  const handleShowAllToggle = () => {
    const visibleAreas = highlightedAreas.filter(area => area.enabled === true);
    if (visibleAreas.length === highlightedAreas.length) {
      const allAreas = highlightedAreas.map(area => ({
        ...area,
        enabled: false
      }));
      setHighlightedAreas(allAreas);
    } else {
      const allAreas = highlightedAreas.map(area => ({
        ...area,
        enabled: true
      }));
      setHighlightedAreas(allAreas);
    }
  };

  // handlers
  const feedbackToggleClick = async e => {
    const newFeedback = {
      ...album.feedback,
      [e.target.name]: !album.feedback[e.target.name]
    };
    const inputValues = {
      id: album.id,
      source: album.source,
      ...newFeedback
    };
    try {
      await updateFeedback({ variables: inputValues });
      toast.success(t('case.case_update_success'));
    } catch (err) {
      toast.error(t('case.case_update_failure', { error }));
    }
  };

  const handleRefreshClick = () => {
    refetch();
  };

  const handleThumbnailClick = idx => {
    setFocusedPhotoIndex(idx);
  };

  const availableModelFamilies = () => {
    if (account) {
      return account.models.map(model => model.modelFamily);
    }
    return [];
  };

  const photoUploadedTrigger = () => {
    setTimeout(() => refetch(), 3000);
  };

  if (loading) return <ItemLoading />;
  if (error) return <h1>Error occured</h1>;
  if (album == null) return <ItemNotFound showGoBackButton />;

  const filteredModelresults = focusedPhoto
    ? filterAvailableModels(
        focusedPhoto.modelResults.v1,
        availableModelFamilies()
      )
    : [];

  const groupedResults =
    focusedPhoto && focusedPhoto.modelResults && focusedPhoto.modelResults.v1
      ? groupModelResults(filteredModelresults, account.models)
      : {};

  const exifData =
    focusedPhoto && focusedPhoto.exifData && focusedPhoto.exifData.v1
      ? focusedPhoto.exifData.v1
      : null;

  const visibleAreas = highlightedAreas.filter(area => area.enabled === true);

  return (
    <>
      <CaseDetailHeader
        id={id}
        caseName={album.name}
        isChecked={album.feedback.casecheck}
        isModelError={album.feedback.modelerror}
        isExifError={album.feedback.exiferror}
        isCar={album.summary ? album.summary.iscar : false}
        brandName={album.summary ? album.summary.make.label : null}
        modelName={album.summary ? album.summary.model.label : null}
        hasAllViews={album.summary ? album.summary.allviews : false}
        viewAggragation={album.summary ? album.summary.viewChecklist : []}
        caseAggregation={
          album.summary ? getCaseAggregationFromAlbum(album.summary) : null
        }
        summary={album?.summary}
        summaryV2={album?.summaryV2}
        isFeedbackUpdating={isFeedbackUpdating}
        handleFeedbackToggleClick={feedbackToggleClick}
        handleRefreshCaseClick={() => handleRefreshClick()}
        status={album.status}
        exifStatus={album?.summary ? album?.summary?.exifstatus : null}
        isAnnotable={isAnnotable}
      />
      <Container className="mt--5" fluid>
        <Row className="mt-4">
          <Col>
            <Row>
              <Col>
                <Card className="p-3">
                  {album.source === 'gui' && (
                    <Row>
                      <Col>
                        <Dropzone
                          albumId={id}
                          uploadFinishedTrigger={photoUploadedTrigger}
                        />
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col className="d-flex flex-row flex-wrap justify-content-center">
                      {photos &&
                        [...photos]
                          .map((item, idx) => ({
                            ...item,
                            originalIdx: idx
                          }))
                          .sort(compareByCreatedAt)
                          .map(photo => {
                            const photoSummary = photoSummaries.find(
                              summary => summary.photoId === photo.id
                            );
                            return (
                              <ImageGalleryItem
                                imageKey={photo.id}
                                thumbnailPath={photo.thumbnail.key}
                                valid={
                                  photoSummary
                                    ? validPhotoSummary(
                                        photoSummary.expected,
                                        photoSummary.found
                                      )
                                    : undefined
                                }
                                imageTitle={
                                  photoSummary ? photoSummary.found : undefined
                                }
                                s3
                                onClick={() =>
                                  handleThumbnailClick(photo.originalIdx)
                                }
                              />
                            );
                          })}
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col
                    className="d-flex flex-column mt-4"
                    sm="12"
                    lg="6"
                    xl="3"
                  >
                    {focusedPhoto && (
                      <S3Image
                        path={focusedPhoto.fullsize.key.replace('public/', '')}
                        theme={{
                          photoImg: { cursor: 'pointer', width: '100%' }
                        }}
                        className="rounded shadow-lg"
                        onClick={() => setShowModal(true)}
                      />
                    )}
                  </Col>

                  {Object.keys(groupedResults)
                    .sort()
                    .map(k => (
                      <Col className="mt-4" sm="12" lg="6" xl="3">
                        <ModelResultsTable
                          modelFamilyName={t('model_group_name', {
                            context: k
                          })}
                          icon={icon(k)}
                          iconColor="bg-info"
                          modelResults={modelResultsToTable(groupedResults[k])}
                        />
                      </Col>
                    ))}

                  {exifData && exifData.length > 0 && (
                    <Col className="mt-4" sm="12" lg="6" xl="3">
                      <ExifDataTable
                        modelFamilyName="Exif"
                        icon="fas fa-camera"
                        iconColor="bg-yellow"
                        modelResults={exifData.map(d => ({
                          label: d.key,
                          score: d.value
                        }))}
                      />
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {showModal && (
        <ModalWithNavigation
          showNavigation
          onLeftClick={
            previousIndex !== null
              ? () => setFocusedPhotoIndex(previousIndex)
              : null
          }
          onRightClick={
            nextIndex !== null ? () => setFocusedPhotoIndex(nextIndex) : null
          }
          onClose={() => setShowModal(false)}
          title={`${focusedPhoto.id} (${focusedPhotoIndex + 1}/${
            photos.length
          })`}
        >
          <div className="modal-body modal-max-width modal-80-width pl-5 pr-5">
            <Row>
              <Col>
                <div className="d-flex justify-content-between">
                  <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                    <input
                      className="custom-control-input"
                      id="customCheck5"
                      type="checkbox"
                      checked={
                        visibleAreas.length === highlightedAreas.length &&
                        highlightedAreas.length > 0
                      }
                      disabled={highlightedAreas.length < 1}
                      onClick={() => handleShowAllToggle()}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheck5"
                    >
                      {t('show bounding boxes')}
                    </label>
                  </div>
                  <div>
                    <Link
                      to={{
                        pathname: `/public-image?src=${btoa(focusedImageUrl)}`
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="link">{t('open in new tab')}</span>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                {highlightedAreas.map(area => (
                  <Button
                    type="button"
                    className="mb-2 ml-0"
                    onClick={() => handleHighlightedAreaToggle(area.id)}
                    style={{
                      backgroundColor: area.enabled && area.strokeColor
                    }}
                  >
                    {area.name} <FormattedPercentage value={area.percentage} />
                  </Button>
                ))}
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Card className="shadow">
                  <ImageWithHighlightedArea
                    imageUrl={focusedImageUrl}
                    originalImageWidth={focusedPhoto.fullsize.width}
                    originalImageHeight={focusedPhoto.fullsize.height}
                    highlightedAreas={visibleAreas}
                  />
                </Card>
              </Col>
            </Row>
          </div>
        </ModalWithNavigation>
      )}
    </>
  );
}

export default CasesDetail;
