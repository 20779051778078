function nextAndPreviousIndex(currentIndex, nOfItems) {
  let nextIndex = null;
  let previousIndex = null;

  if (Number.isNaN(currentIndex)) {
    return {
      nextIndex,
      previousIndex
    };
  }

  // next index
  if (currentIndex + 1 < nOfItems) {
    nextIndex = currentIndex + 1;
  }

  // previous index
  if (currentIndex - 1 >= 0) {
    previousIndex = currentIndex - 1;
  }

  return {
    nextIndex,
    previousIndex
  };
}

export default nextAndPreviousIndex;
