function modelResultsToLabeledBoundingBoxes(modelResults) {
  const labeledBoundigBoxes = modelResults.reduce(
    (prev, curr) => [
      ...prev,
      ...curr.results.map(r => ({
        model: curr.name,
        label: r.label,
        score: r.score,
        box: r.box
      }))
    ],
    []
  );

  return labeledBoundigBoxes;
}

export default modelResultsToLabeledBoundingBoxes;
