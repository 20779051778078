import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Storage } from 'aws-amplify';
import { v4 } from 'uuid';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

function Dropzone({ albumId, uploadFinishedTrigger }) {
  const { t } = useTranslation();
  const [isUploading, setIsUploading] = useState(false);

  const uploadFile = async (albId, file) => {
    try {
      const key = v4();
      await Storage.put(key, file, {
        customPrefix: { public: 'uploads/' },
        metadata: { albumid: albId }
      });
      toast.success(t('general.dropzone.upload_photo_success'));
    } catch (error) {
      toast.error(t('general.dropzone.upload_photo_failure', { error }));
    }
  };

  const onDrop = async files => {
    setIsUploading(true);
    files.forEach(f => uploadFile(albumId, f));
    setIsUploading(false);
    uploadFinishedTrigger();
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>{t('general.dropzone.drop_file_here')}...</p>
      ) : (
        <div
          className={classnames('dropzone', {
            'dropzone--disabled': isUploading
          })}
        >
          {isUploading
            ? t('general.dropzone.uploading')
            : t('general.dropzone.drag_n_drop_files_here')}
        </div>
      )}
    </div>
  );
}

Dropzone.propTypes = {
  albumId: PropTypes.string.isRequired,
  uploadFinishedTrigger: PropTypes.func
};

Dropzone.defaultProps = {
  uploadFinishedTrigger: () => {}
};

export default Dropzone;
