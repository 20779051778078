const regionsToModelResults = regions => {
  return regions.map(r => ({
    id: r.id,
    groupId: r.groupId,
    text: JSON.stringify([
      {
        label: r.cls,
        text: r.text
      }
    ]),
    label: r.cls,
    score: r.score || 1,
    box: {
      X1: r.x,
      Y1: r.y,
      X2: r.x + r.w,
      Y2: r.y + r.h
    }
  }));
};

export default regionsToModelResults;
