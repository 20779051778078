import React, { useState, useEffect } from 'react';
import Chart from 'chart.js';
import { Container, Row, Col } from 'reactstrap';
import moment from 'moment';
import MetricsChart from 'components/MetricsChart';
import MetricsHeader from 'components/Headers/MetricsHeader';
import { chartOptions, parseOptions } from 'variables/charts';
import gql from 'graphql-tag';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import sleep from 'utils/sleep';
import compareMetrics from 'utils/compareMetrics';

// these should be loaded from BE or hardcoded
const periodOptions = [
  {
    value: '300',
    label: '5m'
  },
  {
    value: '900',
    label: '15m'
  },
  {
    value: '3600',
    label: '1h'
  },
  {
    value: '21600',
    label: '6h'
  },
  {
    value: '86400',
    label: '1d'
  }
];

const statOptions = [
  {
    value: 'Average',
    label: 'avg'
  },
  {
    value: 'Minimum',
    label: 'min'
  },
  {
    value: 'Maximum',
    label: 'max'
  },
  {
    value: 'Sum',
    label: 'sum'
  },
  {
    value: 'SampleCount',
    label: 'count'
  }
];

const timeRangeOptions = [
  {
    value: 60 * 60 * 24,
    label: 'last1day'
  },
  {
    value: 60 * 60 * 24 * 3,
    label: 'last3days'
  },
  {
    value: 60 * 60 * 24 * 7,
    label: 'last1week'
  }
];

const GET_METRICS = gql`
  query listMetrics($metric: String!) {
    listMetrics(namespace: $metric) {
      items {
        name
        namespace
        dimensions {
          name
          value
        }
      }
    }
  }
`;

const GET_METRICS_DATA = gql`
  query listMetricsData($filter: ListMetricDataInput!) {
    listMetricsData(filter: $filter) {
      items {
        label
        timestamps
        values
      }
    }
  }
`;

const initialFilter = {
  stat: statOptions[0].value,
  period: periodOptions[0].value,
  timeRange: timeRangeOptions[0].value,
  metrics: []
};

function borderColor(i) {
  const colors = [
    `rgba(249, 191, 59, 1)`,
    `rgba(154, 18, 179, 1)`,
    `rgba(0, 181, 204, 1)`,
    `rgba(240, 255, 0, 1)`,
    `rgba(240, 52, 52, 1)`,
    `rgba(242, 121, 53, 1)`,
    `rgba(140, 20, 252, 1)`,
    `rgba(228, 241, 254, 1)`,
    `rgba(241, 90, 34, 1)`,
    `rgba(25, 181, 254, 1)`
  ];

  const m = i % 10;

  return colors[m];
}

function timestampsInRange(startAt, endAt, period) {
  const timestamps = [];
  const startAtUnix = moment(`${startAt}Z`)
    .utc()
    .unix();
  const endAtUnix = moment(`${endAt}Z`)
    .utc()
    .unix();
  const periodInt = parseInt(period, 10);

  timestamps.push(endAtUnix);
  let cursor = endAtUnix;
  while (cursor >= startAtUnix) {
    timestamps.push(cursor - periodInt);
    cursor -= periodInt;
  }

  timestamps.sort();

  return timestamps;
}

function populateValuesInAllRange(
  values,
  valuesTimestamps,
  allRangeTimestamps
) {
  const populatedValues = allRangeTimestamps.map(ts => {
    const idx = valuesTimestamps.findIndex(el => el === ts);
    if (idx === -1) {
      return null;
    }
    return parseFloat(values[idx].toFixed(2));
  });

  return populatedValues;
}

function gqlDataToGraphData(mData, beFilter) {
  if (mData.length < 1) {
    return null;
  }

  // all timestamps for whole range (for further calculations)
  const allRangeTimestamps = timestampsInRange(
    beFilter.startAt,
    beFilter.endAt,
    beFilter.period
  );

  // labels
  const labels = allRangeTimestamps.map(timestamp =>
    moment
      .unix(timestamp)
      .utc()
      .format('YYYY-MM-DD HH:mm')
  );

  // // datasets
  const datasets = mData.map(({ label, values, timestamps }, i) => ({
    label,
    borderColor: borderColor(i),
    pointBackgroundColor: borderColor(i),
    pointRadius: 3,
    pointHoverRadius: 5,
    borderWidth: 1,
    data: populateValuesInAllRange(values, timestamps, allRangeTimestamps)
  }));

  return {
    labels,
    datasets
  };
}

function filterToBEFilter({ stat, period, timeRange, metrics }) {
  // calculate startAt and endAt
  const currentUnix = moment()
    .utc()
    .unix();
  const flooredCurrentUnix = currentUnix - (currentUnix % parseInt(period, 10));
  const startAtUnix = flooredCurrentUnix - parseInt(timeRange, 10);
  const endAtUnix = flooredCurrentUnix;

  const startAt = moment
    .unix(startAtUnix)
    .toISOString()
    .split('Z')[0];
  const endAt = moment
    .unix(endAtUnix)
    .toISOString()
    .split('Z')[0];

  // new filter object
  const beFilter = {
    stat,
    startAt,
    endAt,
    period,
    metrics
  };

  return beFilter;
}

function Metrics() {
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }
  const { metric } = qs.parse(useLocation().search);
  const [filter, setFilter] = useState(initialFilter);
  const [isLoading, setIsLoading] = useState(false);
  const [graphData, setGraphData] = useState(null);

  const { data: metricData, loading: metricLoading } = useQuery(GET_METRICS, {
    variables: { metric }
  });

  const [
    getMetricsData,
    { error: mError, data: mData, variables: mVariables }
  ] = useLazyQuery(GET_METRICS_DATA, {
    fetchPolicy: 'no-cache'
  });

  // refresh data from API
  useEffect(() => {
    async function loadData() {
      if (filter.metrics.length === 0) {
        setGraphData(null);
        return;
      }
      setIsLoading(true);
      await sleep(300);
      getMetricsData({
        variables: {
          filter: filterToBEFilter(filter)
        }
      });
      setIsLoading(false);
    }

    if (filter !== null) {
      loadData();
    }
  }, [filter, getMetricsData]);

  // handle filter changes
  const handleInputChange = e => {
    const { type, name, value, checked } = e.target;

    switch (type) {
      case 'select-one':
        setFilter({ ...filter, [name]: value });
        break;
      case 'checkbox':
        const parsedValue = JSON.parse(value); // eslint-disable-line no-case-declarations
        const { metrics } = filter; // eslint-disable-line no-case-declarations
        if (checked) {
          setFilter({ ...filter, metrics: [...metrics, parsedValue] });
        } else {
          setFilter({
            ...filter,
            metrics: metrics.filter(item => !compareMetrics(item, parsedValue))
          });
        }
        break;
      default:
        break;
    }
  };

  // select default metrics when list of metrics loaded
  useEffect(() => {
    if (
      metricData &&
      metricData.listMetrics &&
      metricData.listMetrics.items &&
      metricData.listMetrics.items.length > 0
    ) {
      setFilter(prev => {
        if (prev.metrics.length !== 0) {
          return prev;
        }
        return {
          ...prev,
          metrics: metricData.listMetrics.items.map(i => ({
            ...i,
            id: `id_${Math.random()
              .toString(36)
              .substring(7)}`
          }))
        };
      });
    }
  }, [metricData]);

  // fill empty timestamps
  useEffect(() => {
    if (mData && mData.listMetricsData && mData.listMetricsData.items) {
      setGraphData(
        gqlDataToGraphData(mData.listMetricsData.items, mVariables.filter)
      );
    }
  }, [mVariables, mData]);

  return (
    <>
      <MetricsHeader
        availableMetrics={
          metricData && metricData.listMetrics && metricData.listMetrics.items
            ? metricData.listMetrics.items
            : []
        }
        filter={filter}
        handleInputChange={handleInputChange}
        isLoading={metricLoading}
      />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <MetricsChart
              graphData={graphData}
              statisticOptions={statOptions}
              periodOptions={periodOptions}
              timeRangeOptions={timeRangeOptions}
              handleInputChange={handleInputChange}
              isLoading={isLoading}
              isError={!!mError}
              selectedStatisticOption={filter.stat}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Metrics;
