import React, { useState } from 'react';
import { Card, CardBody, Row, Col, Collapse, Table, Badge } from 'reactstrap';
import PropTypes from 'prop-types';
import Check from 'components/Check';
import numberToPercentage from 'utils/numberToPercentage';
import { useLocation } from 'react-router-dom';
import InspectionNavigation from 'components/InspectionNavigation';
import classnames from 'classnames';
import { useSession } from 'utils/useSession';
import { useTranslation } from 'react-i18next';
import formatTimestamp from 'utils/formatTimestamp';
import SummaryV2 from 'components/SummaryV2';
import InspectionEditableFeedback from '../InspectionEditableFeedback';

function getCaseIdFromUrl(url) {
  const tokens = url.split('/');
  return tokens[2];
}

function InspectionDetailHeader({
  id,
  name,
  source,
  requiredFields,
  sessionStatus,
  createdAt,
  invitedAt,
  confirmedAt,
  viewAggragation,
  caseAggregation,
  summaryV2
}) {
  const { t } = useTranslation();
  const location = useLocation();
  const { sessionVariable, setSessionVariable } = useSession();
  const [isOpen, setIsOpen] = useState(
    sessionVariable('case-detail-show-aggegation')
  );
  const currentCaseId = getCaseIdFromUrl(location.pathname);
  const filteredCaseIds =
    location.state && location.state.filteredCaseIds
      ? location.state.filteredCaseIds
      : [];

  const toggleShowAggregation = () => {
    setSessionVariable('case-detail-show-aggegation', !isOpen);
    setIsOpen(!isOpen);
  };

  return (
    <>
      <InspectionNavigation
        currentCaseId={currentCaseId}
        filteredCaseIds={filteredCaseIds}
      />
      <div className="header-body">
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <div className="d-flex justify-content-between">
                      <div className="d-flex align-items-center">
                        <h1 className="mb-0">{name}</h1>
                        <Badge color="primary" className="ml-2" pill>
                          <i className="fas fa-certificate" />
                          <span className="ml-2">{source}</span>
                        </Badge>
                        <Badge color="success" className="ml-2" pill>
                          <i className="far fa-eye" />
                          <span className="ml-2">
                            {t('inspection.all_views')}
                          </span>
                        </Badge>
                      </div>
                      <div>
                        <span className="h1">
                          <Badge color="success" className="mr-2">
                            <span>
                              {t(
                                `session_status.${sessionStatus.toLowerCase()}`
                              )}
                            </span>
                          </Badge>
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <em className="italic">
                      <small>
                        <strong>{t('inspection.required_fields')}:</strong>{' '}
                        {requiredFields.length > 0
                          ? requiredFields.reduce(
                              (prev, curr) => `${prev}, ${curr}`
                            )
                          : '-'}
                      </small>
                    </em>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <InspectionEditableFeedback id={id} />
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <table className="small">
                      <tr>
                        <td>
                          <strong>{t('inspection.created_at')}:</strong>
                        </td>
                        <td className="pl-2">{formatTimestamp(createdAt)}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>{t('inspection.invited_at')}:</strong>
                        </td>
                        <td className="pl-2">
                          {formatTimestamp(invitedAt || createdAt)}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>{t('inspection.confirmed_at')}:</strong>
                        </td>
                        <td className="pl-2">{formatTimestamp(confirmedAt)}</td>
                      </tr>
                    </table>
                  </Col>
                </Row>
                <Collapse isOpen={isOpen}>
                  {!summaryV2 && (
                    <Row className="mt-4">
                      <Col>
                        {caseAggregation && (
                          <>
                            <h3 className="text-capitalize">
                              {t('case.case_summary')}
                            </h3>
                            <Table className="align-items-center" responsive>
                              <tbody>
                                <tr>
                                  <td className="text-capitalize">
                                    {t('case.manafacturer')}
                                  </td>
                                  <td>{caseAggregation.manafacturer.label}</td>
                                  <td>
                                    {numberToPercentage(
                                      caseAggregation.manafacturer.score
                                    )}{' '}
                                    %
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-capitalize">
                                    {t('case.model')}
                                  </td>
                                  <td>{caseAggregation.model.label}</td>
                                  <td>
                                    {numberToPercentage(
                                      caseAggregation.model.score
                                    )}{' '}
                                    %
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-capitalize">
                                    {t('case.color')}
                                  </td>
                                  <td>{caseAggregation.color.label}</td>
                                  <td>
                                    {numberToPercentage(
                                      caseAggregation.color.score
                                    )}{' '}
                                    %
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-capitalize">
                                    {t('case.car')}
                                  </td>
                                  <td />
                                  <td>
                                    <Check
                                      checked={caseAggregation.caseIsCar}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-capitalize">
                                    {t('case.required_views_present')}
                                  </td>
                                  <td />
                                  <td>
                                    <Check checked={caseAggregation.allViews} />
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </>
                        )}
                      </Col>
                      <Col>
                        <h3 className="text-capitalize">{t('case.views')}</h3>
                        <Table className="align-items-center" responsive>
                          <tbody>
                            {viewAggragation &&
                              viewAggragation.map(item => (
                                <tr key={item.expected}>
                                  <td>
                                    {t(
                                      `views.${item?.expected?.toLowerCase()}`
                                    )}
                                  </td>
                                  <td>{item.count}</td>
                                  <td>
                                    <Check checked={item.check} />
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  )}
                  {summaryV2 && (
                    <Row className="mt-4">
                      {summaryV2.map(({ title, rows }) => (
                        <Col>
                          <SummaryV2 title={title} rows={rows} />
                        </Col>
                      ))}
                    </Row>
                  )}
                </Collapse>
                <Row className="mt-4">
                  <Col>
                    <div
                      className={classnames('text-center', {
                        // 'd-none': !status
                      })}
                    >
                      {/* eslint-disable */}
                          <p

                            className="card__collapse__toggle"
                            onClick={() => toggleShowAggregation()}
                          >
                            {!isOpen
                              ? t('case.show_aggregations')
                              : t('case.hide_aggregations')}
                          </p>
                          {/* eslint-enable */}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

InspectionDetailHeader.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  requiredFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  sessionStatus: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  invitedAt: PropTypes.string.isRequired,
  confirmedAt: PropTypes.string.isRequired,
  caseAggregation: PropTypes.shape({
    manafacturer: PropTypes.shape({
      label: PropTypes.string,
      score: PropTypes.number.isRequired
    }),
    model: PropTypes.shape({
      label: PropTypes.string,
      score: PropTypes.number.isRequired
    }),
    color: PropTypes.shape({
      label: PropTypes.string,
      score: PropTypes.number.isRequired
    }),
    caseIsCar: PropTypes.bool.isRequired,
    allViews: PropTypes.bool.isRequired,
    exifSummary: PropTypes.string.isRequired
  }).isRequired,
  viewAggragation: PropTypes.arrayOf(
    PropTypes.shape({
      expected: PropTypes.string.isRequired,
      check: PropTypes.bool.isRequired,
      count: PropTypes.number.isRequired
    })
  ).isRequired,
  summaryV2: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      rows: PropTypes.arrayOf(
        PropTypes.shape({
          layout: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          label: PropTypes.string,
          count: PropTypes.number,
          score: PropTypes.number,
          check: PropTypes.bool
        })
      )
    })
  )
};

InspectionDetailHeader.defaultProps = {
  summaryV2: []
};

export default InspectionDetailHeader;
