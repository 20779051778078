import { useAccount } from 'utils/useAccount';

const basicRoutes = [
  {
    id: 'home',
    path: '/',
    title: 'Home',
    icon: 'fas fa-tachometer-alt',
    level: 1
  },
  {
    id: 'metrics',
    path: '/metrics',
    title: 'Metrics',
    icon: 'far fa-chart-bar',
    level: 1
  },
  {
    id: 'cases',
    path: '/cases',
    title: 'Cases',
    icon: 'far fa-folder-open',
    level: 1
  },
  {
    id: 'inspections',
    path: '/inspections',
    title: 'Inspections',
    icon: 'fas fa-file-video',
    level: 1
  },
  {
    id: 'exports',
    path: '/exports',
    title: 'Exports',
    icon: 'fas fa-file-export',
    level: 1
  }
];

function addSubroutesBySource(routes, toAddSubroutesRouteIds, sources) {
  if (!sources) {
    return routes;
  }

  const enhancedRoutes = routes.map(route => {
    const r = JSON.parse(JSON.stringify(route));

    if (toAddSubroutesRouteIds.includes(r.id)) {
      const childrenRoutes = sources.map(source => {
        if (r.id === 'exports' && !source.hasExports) {
          return null;
        }
        if (r.id === 'inspections' && !source.hasInspections) {
          return null;
        }
        if (r.id === 'cases' && source.hasInspections) {
          return null;
        }
        return {
          id: `${r.id}?source=${source.filterName}`,
          path: `${r.path}?source=${source.filterName}`,
          title: source.name,
          level: 2
        };
      });

      r.children = childrenRoutes.filter(
        childrenRoute => childrenRoute !== null
      );
    }
    return r;
  });

  return enhancedRoutes;
}

function addSubroutesByMetrics(routes, toAddSubroutesRouteIds, metrics) {
  if (!metrics) {
    return routes;
  }

  const enhancedRoutes = routes.map(route => {
    const r = JSON.parse(JSON.stringify(route));

    if (toAddSubroutesRouteIds.includes(r.id)) {
      const childrenRoutes = metrics.map(metric => {
        return {
          id: `${r.id}?metric=${metric.namespace}`,
          path: `${r.path}?metric=${metric.namespace}`,
          title: metric.displayName,
          level: 2
        };
      });

      r.children = childrenRoutes.filter(
        childrenRoute => childrenRoute !== null
      );
    }
    return r;
  });

  return enhancedRoutes;
}

function useRoutes() {
  const { sources, metrics } = useAccount();
  const withSourcesRoutes = addSubroutesBySource(
    basicRoutes,
    ['cases', 'exports', 'inspections'],
    sources
  );
  const withMetricRoutes = addSubroutesByMetrics(
    withSourcesRoutes,
    ['metrics'],
    metrics
  );
  const pureRoutes = withMetricRoutes.map(r => {
    const rr = { ...r };
    if (rr.children) {
      rr.path = `disabled-${r.path}`;
    }
    return rr;
  });
  return {
    routes: pureRoutes
  };
}

export default useRoutes;
