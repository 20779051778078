import React from 'react';
import { Badge } from 'reactstrap';
import PropTypes from 'prop-types';
import { colorStringToHex } from 'utils/color';
import { useTranslation } from 'react-i18next';

function CarColorBadge({ color }) {
  const { t } = useTranslation();
  const colorHex = colorStringToHex(color);

  return (
    <Badge
      className="mr-1"
      style={{
        backgroundColor: `#${colorHex.backgroundColor}`
      }}
    >
      <span
        style={{
          color: `#${colorHex.textColor}`
        }}
      >
        {t(`colors.${color}`)}
      </span>
    </Badge>
  );
}

CarColorBadge.propTypes = {
  color: PropTypes.string.isRequired
};

export default CarColorBadge;
