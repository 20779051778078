import React, { useState } from 'react';
import { Modal, Button } from 'reactstrap';
import { useAccount } from 'utils/useAccount';
import { useTranslation } from 'react-i18next';
import selectSource from 'utils/selectSource';
import { useLocation } from 'react-router-dom';
import * as qs from 'query-string';
import SendInvitationForm from './SendInvitationForm';

function SendInvitationButton() {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const { sources } = useAccount();
  const hasInspectionsSources =
    sources && sources.filter(source => source.hasInspections === true);
  const { search } = useLocation();
  const { source: urlSource } = qs.parse(search);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const defaultSource = selectSource(hasInspectionsSources, urlSource);

  return (
    <>
      <Button
        color="success"
        onClick={toggleModal}
        disabled={hasInspectionsSources?.length <= 0}
      >
        {t('invitation.invite_via_sms')}
      </Button>
      {modalOpen && (
        <Modal
          className="modal-dialog-centered"
          isOpen={modalOpen}
          toggle={toggleModal}
          autoFocus={false}
        >
          <SendInvitationForm
            sources={hasInspectionsSources}
            onClose={toggleModal}
            defaultSource={defaultSource}
          />
        </Modal>
      )}
    </>
  );
}

export default SendInvitationButton;
