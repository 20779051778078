import React from 'react';
import { Table, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Check from 'components/Check';
import FeedbackBadgeGroup from 'components/FeedbackBadgeGroup';
import CarColorBadge from 'components/CarColorBadge';
import formatTimestamp from 'utils/formatTimestamp';
import { compareByCreatedAtDesc } from 'utils/compareFunctions';
import { useTranslation } from 'react-i18next';

function CasesTable({ items }) {
  const { t } = useTranslation();
  const filteredCaseIds = items
    .sort(compareByCreatedAtDesc)
    .map(item => item.id);

  return (
    <Table className="align-items-center table-flush" responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col">{t('case.table.case_name')}</th>
          <th scope="col">{t('case.table.created_at')}</th>
          <th scope="col">{t('case.table.status')}</th>
          <th scope="col">{t('case.table.car')}</th>
          <th scope="col">{t('case.table.car_model')}</th>
          <th scope="col">{t('case.table.color')}</th>
          <th scope="col">{t('case.table.exif_summary')}</th>
          <th scope="col">{t('case.table.views')}</th>
          <th scope="col">{t('case.table.feedback')}</th>
          <th scope="col">{t('case.table.actions')}</th>
        </tr>
      </thead>
      <tbody>
        {items.sort(compareByCreatedAtDesc).map(item => (
          <tr key={item.id}>
            <td>
              <Link
                className="mr-2"
                to={{
                  pathname: `/cases/${item.id}`,
                  state: {
                    filteredCaseIds
                  }
                }}
              >
                {item.name}
              </Link>
            </td>
            <td>{formatTimestamp(item.createdAt)}</td>
            <td>
              <Check checked={item.status} />
            </td>
            <td>
              <Check checked={item.isCar} />
            </td>
            <td>{item.carModel}</td>
            <td>
              <CarColorBadge color={item.color} />
            </td>
            <td>{item.exifStatus}</td>
            <td>
              <Check checked={item.views} />
            </td>
            <td>
              <FeedbackBadgeGroup feedback={item.feedback} />
            </td>
            <td>
              {item.isAnnotable === true && (
                <Link
                  to={{
                    pathname: `/cases/${item.id}/annotate`
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button color="info" type="button" size="sm" outline>
                    {t('case.annotate')}
                  </Button>
                </Link>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

CasesTable.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      status: PropTypes.bool.isRequired,
      isCar: PropTypes.bool.isRequired,
      model: PropTypes.string,
      color: PropTypes.string.isRequired,
      exifStatus: PropTypes.string.isRequired,
      views: PropTypes.bool.isRequired,
      isAnnotable: PropTypes.bool.isRequired
    })
  ).isRequired
};

export default CasesTable;
