import React, { useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  UncontrolledAlert,
  Col
} from 'reactstrap';
import { signUp } from 'utils/auth';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function Register(props) {
  const { t } = useTranslation();
  const defaultValues = {
    username: '',
    email: '',
    password: ''
  };

  const [values, setValues] = useState(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const [registerError, setRegisterError] = useState(null);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleOnSubmit = async () => {
    const { username, email, password, phone } = values;
    const { history } = props;

    setIsLoading(true);
    try {
      await signUp(username, email, password, phone);
      setIsLoading(false);
      setRegisterError(null);
      toast.success(t('register.register_success'));
      history.push({
        pathname: '/confirm-signup',
        search: `?username=${username}`
      });
    } catch (error) {
      toast.error(t('register.register_failure', { error }));
      setIsLoading(false);
      setRegisterError(error);
      // resetValues();
    }
  };

  return (
    <>
      <Col lg="6" md="8">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            {registerError && (
              <UncontrolledAlert color="danger" fade={false}>
                <span className="alert-inner--icon">
                  <i className="ni ni-circle-08" />
                </span>{' '}
                {registerError.message}
              </UncontrolledAlert>
            )}
            <div className="text-center text-muted mb-4">
              <small>{t('register.create_new_account')}</small>
            </div>
            <Form role="form">
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-circle-08" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    id="username"
                    name="username"
                    placeholder={t('register.username_placeholder')}
                    type="text"
                    value={values.username}
                    onChange={handleInputChange}
                    required
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    id="email"
                    name="email"
                    placeholder={t('register.email_placeholder')}
                    type="email"
                    value={values.email}
                    onChange={handleInputChange}
                    required
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-mobile-button" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    id="phone"
                    name="phone"
                    placeholder={t('register.phone_placeholder')}
                    type="text"
                    value={values.phone}
                    onChange={handleInputChange}
                    required
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    id="password"
                    name="password"
                    type="password"
                    value={values.password}
                    onChange={handleInputChange}
                    placeholder={t('register.password_placeholder')}
                    required
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button
                  className="mt-4"
                  color="primary"
                  type="button"
                  onClick={handleOnSubmit}
                  disabled={isLoading}
                >
                  {isLoading
                    ? t('register.creating_account')
                    : t('register.create_account')}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
}

Register.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default Register;
