import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { S3Image } from 'aws-amplify-react';
import icon from 'utils/modelDisplayTypeIcons';
import ModelResultsTable from 'components/ModelResultsTable';
import modelResultsToTable from 'utils/modelResultsToTable';

function GalleryDetail({ photo, groupedResults, onPictureClick }) {
  const { t } = useTranslation();

  return (
    <Row>
      <Col className="d-flex flex-column mt-4" sm="12" lg="6" xl="3">
        {photo && (
          <S3Image
            path={photo.path.replace('public/', '')}
            theme={{
              photoImg: { cursor: 'pointer', width: '100%' }
            }}
            className="rounded shadow-lg"
            onClick={onPictureClick}
          />
        )}
      </Col>

      {Object.keys(groupedResults)
        .sort()
        .map(k => (
          <Col className="mt-4" sm="12" lg="6" xl="3">
            <ModelResultsTable
              modelFamilyName={t('model_group_name', {
                context: k
              })}
              icon={icon(k)}
              iconColor="bg-info"
              modelResults={modelResultsToTable(groupedResults[k])}
            />
          </Col>
        ))}
    </Row>
  );
}

GalleryDetail.propTypes = {
  photo: PropTypes.shape({
    path: PropTypes.string.isRequired
  }).isRequired,
  groupedResults: PropTypes.arrayOf(PropTypes.any).isRequired,
  onPictureClick: PropTypes.func.isRequired
};

export default GalleryDetail;
