import React from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';
import compareMetrics from 'utils/compareMetrics';
import { useTranslation } from 'react-i18next';

function normalizeMetric(metric) {
  const metricId = `id_${Math.random()
    .toString(36)
    .substring(7)}`;
  const dimensions = metric.dimensions.map(({ name, value }) => ({
    name,
    value
  }));
  const { name, namespace } = metric;
  const statusCode = metric.dimensions.find(d => d.name === 'Status code')
    .value;
  const endpoint = metric.dimensions.find(d => d.name === 'Endpoint').value;
  const escapedName = encodeURIComponent(metric.name);
  return {
    id: `${escapedName}-${endpoint}-${statusCode} `,
    label: `${metric.name} - ${endpoint} ${statusCode} `,
    metric: {
      id: metricId,
      name,
      namespace,
      dimensions
    }
  };
}

function MetricsHeader({
  availableMetrics,
  filter,
  handleInputChange,
  isLoading
}) {
  const { t } = useTranslation();
  const { metrics } = filter;
  const normalizedMetric = availableMetrics.map(m => normalizeMetric(m));
  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col>
                        <h4>{t('metrics.metrics')}</h4>
                      </Col>
                    </Row>
                    <Row>
                      {normalizedMetric.map(nm => (
                        <Col className="col-3" key={nm.id}>
                          <div className="custom-control custom-checkbox mb-3">
                            <input
                              className="custom-control-input"
                              id={nm.id}
                              type="checkbox"
                              onChange={handleInputChange}
                              value={JSON.stringify(nm.metric)}
                              checked={
                                metrics.findIndex(item =>
                                  compareMetrics(item, nm.metric)
                                ) > -1
                              }
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={nm.id}
                            >
                              {nm.label}
                            </label>
                          </div>
                        </Col>
                      ))}
                      {isLoading && <Loader title="loading data" />}
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

MetricsHeader.propTypes = {
  metrics: PropTypes.arrayOf(PropTypes.shape(PropTypes.any)),
  availableMetrics: PropTypes.arrayOf(
    PropTypes.shape({
      namespace: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      dimensions: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired
        })
      )
    })
  ).isRequired,
  filter: PropTypes.shape({
    selectedMetrics: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired
      })
    )
  }).isRequired,
  handleInputChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

MetricsHeader.defaultProps = {
  metrics: []
};

export default MetricsHeader;
