export default function selectSource(sources, urlSource) {
  if (!sources || sources.length === 0) {
    return '';
  }
  const idx = sources.findIndex(i => i.filterName === urlSource);
  if (idx === -1) {
    return sources[0].filterName;
  }
  return sources[idx].filterName;
}
