import * as _ from 'lodash';

function compareMetrics(availableMetric, normalizedMetric) {
  if (availableMetric.name !== normalizedMetric.name) {
    return false;
  }
  if (availableMetric.namespace !== normalizedMetric.namespace) {
    return false;
  }
  if (!_.isEqual(availableMetric.dimensions, normalizedMetric.dimensions)) {
    return false;
  }
  return true;
}

export default compareMetrics;
