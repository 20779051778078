import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const GET_ALBUM_FEEDBACK_QUERY = gql`
  query getFeedback($albumId: ID!) {
    getAlbum(id: $albumId) {
      id
      source
      feedback {
        casecheck
        exiferror
        modelerror
      }
    }
  }
`;

const UPDATE_FEEDBACK_MUTATION = gql`
  mutation updateFeedback($input: UpdateFeedbackInput!) {
    updateAlbumFeedback(input: $input) {
      id
      source
      feedback {
        casecheck
        exiferror
        modelerror
      }
    }
  }
`;

function EditableFeedback({ caseId }) {
  const { t } = useTranslation();
  const { error, data } = useQuery(GET_ALBUM_FEEDBACK_QUERY, {
    variables: { albumId: caseId }
  });
  const [updateFeedback, { loading: updateFeedbackLoading }] = useMutation(
    UPDATE_FEEDBACK_MUTATION
  );
  const { feedback } = data?.getAlbum || {};

  // handlers
  const handleFeedbackToggleClick = async name => {
    const newFeedback = {
      ...feedback,
      [name]: !feedback[name]
    };
    // eslint-disable-next-line no-underscore-dangle
    delete newFeedback.__typename;
    delete newFeedback.undefined;
    const input = {
      id: caseId,
      source: data?.getAlbum?.source,
      ...newFeedback
    };
    try {
      await updateFeedback({ variables: { input } });
      toast.success(t('case.case_update_success'));
    } catch (err) {
      toast.success(t('case.case_update_failure', { error }));
    }
  };

  return (
    <div>
      <Button
        color={feedback.casecheck ? 'success' : 'secondary'}
        type="button"
        name="casecheck"
        onClick={() => handleFeedbackToggleClick('casecheck')}
        disabled={updateFeedbackLoading}
      >
        <span className="text-uppercase">{t('case.feedback.checked')}</span>
      </Button>
      <Button
        color={feedback.modelerror ? 'danger' : 'secondary'}
        type="button"
        name="modelerror"
        onClick={() => handleFeedbackToggleClick('modelerror')}
        disabled={updateFeedbackLoading}
      >
        <span className="text-uppercase">{t('case.feedback.model_error')}</span>
      </Button>
      <Button
        color={feedback.exiferror ? 'danger' : 'secondary'}
        type="button"
        name="exiferror"
        onClick={() => handleFeedbackToggleClick('exiferror')}
        disabled={updateFeedbackLoading}
      >
        <span className="text-uppercase">{t('case.feedback.exif_error')}</span>
      </Button>
    </div>
  );
}

EditableFeedback.propTypes = {
  caseId: PropTypes.string.isRequired
};

export default EditableFeedback;
