import moment from 'moment';

function addTimeToDates(filter) {
  const { from, to } = filter;
  return {
    ...filter,
    from: moment
      .utc(from)
      .startOf('day')
      .toISOString(),
    to: moment
      .utc(to)
      .endOf('day')
      .toISOString()
  };
}

export default addTimeToDates;
