import React from 'react';
import { NavItem, NavLink, Nav, Container, Row, Col } from 'reactstrap';

function PublicFooter() {
  return (
    <>
      <footer className="py-5">
        <Container>
          <Row className="align-items-center justify-content-xl-between">
            <Col xl="6">
              <div className="copyright text-center text-xl-left text-muted">
                © 2020{' '}
                <a
                  className="font-weight-bold ml-1"
                  href="https://www.searpent.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Searpent
                </a>
              </div>
            </Col>
            <Col xl="6">
              <Nav className="nav-footer justify-content-center justify-content-xl-end">
                <NavItem>
                  <NavLink href="https://www.searpent.com" target="_blank">
                    Searpent
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default PublicFooter;
