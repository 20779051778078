/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import useAmplifyAuth from 'utils/useAmplifyAuth';
import { toast } from 'react-toastify';

function PrivateRoute({ component: Component, ...rest }) {
  const {
    state: { isLoading, isError, user }
  } = useAmplifyAuth();

  if (isLoading) {
    return <h1>LOADING AUTH PAGE</h1>;
  }

  if (isError || !user) {
    toast.error('You are not authorized!');

    return (
      <Redirect
        to={{
          pathname: '/login'
        }}
      />
    );
  }

  return <Route {...rest} render={props => <Component {...props} />} />;
}

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired
};

export default PrivateRoute;
