const routes = [
  {
    path: '/',
    name: 'Home',
    icon: 'fas fa-tachometer-alt'
  },
  {
    path: '/cases',
    name: 'Cases',
    icon: 'far fa-folder-open'
  },
  {
    path: '/exports',
    name: 'Exports',
    icon: 'fas fa-file-export'
  }
];
export default routes;
