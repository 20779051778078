import modelResultsToLabeledBoundingBoxes from 'utils/modelResultsToLabeledBoundingBoxes';
import stringToColor from 'utils/stringToColor';

function modelResultsToHighlightedAreas(modelResults) {
  const bboxes = modelResultsToLabeledBoundingBoxes(modelResults);
  const highlightedAreas = bboxes
    ? bboxes.map(bbox => ({
        id: `${bbox.model}:${bbox.label}`,
        name: `${bbox.label}`,
        percentage: bbox.score,
        shape: 'rect',
        relativeCoords:
          bbox && bbox.box
            ? [bbox.box.x1, bbox.box.y1, bbox.box.x2, bbox.box.y2]
            : null,
        preFillColor: `rgba(${stringToColor(
          `${bbox.model}:${bbox.label}`
        )}, 0.5)`,
        fillColor: `rgba(${stringToColor(
          `${bbox.model}:${bbox.label}`
        )}, 0.75)`,
        strokeColor: `rgba(${stringToColor(`${bbox.model}:${bbox.label}`)})`,
        lineWidth: 1,
        enabled: false
      }))
    : [];
  return highlightedAreas;
}

export default modelResultsToHighlightedAreas;
