import stringToASCIISum from 'utils/stringToASCIISum';
import colors from 'variables/colors';

function stringToColor(s) {
  const value = stringToASCIISum(s);
  const m = value % colors.length;
  return colors[m];
}

export default stringToColor;
