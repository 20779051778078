import React, { useState, useCallback } from 'react';
import {
  Button,
  Badge,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import useAmplifyAuth from 'utils/useAmplifyAuth';
import MFAModal from 'components/MFAModal';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';

function MFASettings() {
  const { t } = useTranslation();
  const {
    state: { user },
    refetchUser
  } = useAmplifyAuth();
  const [qrCode, setQrCode] = useState('');

  const generateQRCode = useCallback(async () => {
    const code = await Auth.setupTOTP(user);
    const issuer = 'SearpentClassy';
    const authCode = `otpauth://totp/AWSCognito:${user.username}?secret=${code}&issuer=${issuer}`;
    setQrCode(authCode);
  }, [user]);

  const enableMFA = useCallback(
    async token => {
      try {
        await Auth.verifyTotpToken(user, token);
        await Auth.setPreferredMFA(user, 'TOTP');
        toast.success(t('settings.mfa.mfa_activate_success'));
        setQrCode('');
        refetchUser();
      } catch (error) {
        toast.error(`${t('settings.mfa.mfa_activate_fail')}: ${error.message}`);
      }
    },
    [refetchUser, t, user]
  );

  const disableMFA = useCallback(async () => {
    try {
      await Auth.setPreferredMFA(user, 'NOMFA');
      toast.success(t('settings.mfa.mfa_deactivate_success'));
      setQrCode('');
      refetchUser();
    } catch (error) {
      toast.error(`${t('settings.mfa.mfa_deactivate_fail')}: ${error.message}`);
    }
  }, [refetchUser, t, user]);

  const mfaEnabled = user?.preferredMFA === 'SOFTWARE_TOKEN_MFA';

  return (
    <>
      <Card className="card-stats mb-4 mb-xl-0">
        <CardHeader>
          <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
            {t('general.security')}
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <p>{t('general.mfa')}</p>
            </Col>
            <Col>
              {mfaEnabled ? (
                <Badge color="success">{t('settings.mfa.activated')}</Badge>
              ) : (
                <Badge color="secondary">{t('settings.mfa.deactivated')}</Badge>
              )}
            </Col>
            <Col>
              <Button
                color="success"
                type="button"
                size="sm"
                outline
                disabled={mfaEnabled}
                onClick={generateQRCode}
              >
                {t('settings.mfa.enable')}
              </Button>

              <Button
                color="danger"
                type="button"
                size="sm"
                outline
                disabled={!mfaEnabled}
                onClick={disableMFA}
              >
                {t('settings.mfa.disable')}
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <MFAModal
        qrCode={qrCode}
        toggleModal={() => setQrCode('')}
        onSubmit={enableMFA}
      />
    </>
  );
}

export default MFASettings;
