import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container
} from 'reactstrap';
import { Auth } from 'aws-amplify';
import LanguageSelector from 'components/LanguageSelector';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from './BreadCrumbs';

function AdminNavbar() {
  const { t } = useTranslation();
  const [userName, setUserName] = useState(null);
  const { push } = useHistory();

  useEffect(() => {
    async function loadAuthenticatedUSer() {
      const user = await Auth.currentAuthenticatedUser();
      setUserName(user.username);
    }

    loadAuthenticatedUSer();
  }, []);

  const redirectTo = link => {
    push(link);
  };

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <BreadCrumbs />
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <LanguageSelector />
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <span className="mb-0 text-sm font-weight-bold">
                  {userName && userName}{' '}
                  <i className="fas fa-chevron-down pl-1" />
                </span>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem onClick={() => redirectTo('/settings')}>
                  <i className="fa fa-lock" aria-hidden="true" />
                  <span>{t('general.settings')}</span>
                </DropdownItem>
                <DropdownItem onClick={() => redirectTo('/logout')}>
                  <i className="ni ni-user-run" />
                  <span>{t('general.logout')}</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}

export default AdminNavbar;
