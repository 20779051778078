import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const filtersContext = createContext();

function useProvideFilters() {
  const [cachedFilters, setCachedFilters] = useState({});

  const cacheFilter = (filterFor, filter) => {
    setCachedFilters({ ...cachedFilters, [filterFor]: filter });
  };

  const getCachedFilter = filterFor => {
    return cachedFilters[filterFor];
  };

  return {
    cachedFilters,
    cacheFilter,
    getCachedFilter
  };
}

export function FiltersProvider({ children }) {
  const filters = useProvideFilters();
  return (
    <filtersContext.Provider value={filters}>
      {children}
    </filtersContext.Provider>
  );
}

FiltersProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export const useFilters = () => {
  return useContext(filtersContext);
};
