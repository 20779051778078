import React, { useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Button
} from 'reactstrap';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function MFAModal({ qrCode, toggleModal, onSubmit }) {
  const { t } = useTranslation();

  const [values, setValues] = useState({
    code: ''
  });

  const handleInputChange = e => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = () => {
    onSubmit(values.code);
  };

  return (
    <Modal
      className="modal-dialog-centered text-center"
      isOpen={qrCode !== ''}
      toggle={toggleModal}
      autoFocus={false}
    >
      <ModalHeader>{t('settings.mfa.setup')}</ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <h4 className="mb-4">{t('settings.mfa.scan_qr')}</h4>
            <QRCode value={qrCode} />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label className="form-control-Label mt-4" htmlFor="code">
                {t('settings.mfa.paste_code')}
              </Label>
              <Input
                type="text"
                name="code"
                id="code"
                onChange={handleInputChange}
                value={values.code}
                autoFocus
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Row>
          <Col>
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={toggleModal}
            >
              {t('general.cancel')}
            </Button>
            <Button color="success" type="button" onClick={handleSubmit}>
              {t('general.submit')}
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
}

MFAModal.propTypes = {
  qrCode: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default MFAModal;
