import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import createApolloClient from 'utils/apolloClient';
import { AccountProvider } from 'utils/useAccount';
import { FiltersProvider } from 'utils/useFilters';
import { FilterCacheProvider } from 'utils/useFilter';
import { SessionProvider } from 'utils/useSession';
import PrivateRoute from 'utils/privateRoute';
import PublicLayout from 'layouts/PublicLayout';
import AdminLayout from 'layouts/AdminLayout';
import AnnotationLayout from 'layouts/AnnotationLayout';
import FullscreenLoader from 'components/FullscreenLoader';
import TermsAndConditions from 'views/TermsAndConditions';
import PublicImage from 'views/PublicImage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import 'assets/vendor/nucleo/css/nucleo.css';
import 'assets/vendor/@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/scss/argon-dashboard-react.scss';

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

export default function App() {
  const client = createApolloClient({
    uri: awsconfig.aws_appsync_graphqlEndpoint,
    cache: true
  });

  return (
    <>
      <BrowserRouter>
        <FilterCacheProvider>
          <ToastContainer hideProgressBar />
          <Switch>
            {/* PUBLIC ROUTES */}
            <Route exact path="/login" component={PublicLayout} />
            <Route exact path="/logout" component={PublicLayout} />
            <Route exact path="/register" component={PublicLayout} />
            <Route exact path="/forgot-password" component={PublicLayout} />
            <Route exact path="/confirm-signup" component={PublicLayout} />
            <Route exact path="/new-password" component={PublicLayout} />
            <Route
              exact
              path="/terms-and-conditions"
              component={TermsAndConditions}
            />
            <ApolloProvider client={client}>
              <Route path="/public-image" component={PublicImage} />
              <SessionProvider>
                <AccountProvider>
                  <FiltersProvider>
                    <FullscreenLoader />

                    {/* APP ROUTES */}
                    <PrivateRoute exact path="/" component={AdminLayout} />
                    <PrivateRoute exact path="/cases" component={AdminLayout} />
                    <PrivateRoute
                      exact
                      path="/cases/:id"
                      component={AdminLayout}
                    />
                    <PrivateRoute
                      exact
                      path="/cases/:id/annotate"
                      component={AnnotationLayout}
                    />
                    <PrivateRoute
                      exact
                      path="/inspections"
                      component={AdminLayout}
                    />
                    <PrivateRoute
                      exact
                      path="/inspections/:id"
                      component={AdminLayout}
                    />
                    <PrivateRoute
                      exact
                      path="/exports"
                      component={AdminLayout}
                    />
                    <PrivateRoute
                      exact
                      path="/metrics"
                      component={AdminLayout}
                    />
                    <PrivateRoute
                      exact
                      path="/settings"
                      component={AdminLayout}
                    />
                  </FiltersProvider>
                </AccountProvider>
              </SessionProvider>
            </ApolloProvider>
          </Switch>
        </FilterCacheProvider>
      </BrowserRouter>
    </>
  );
}
