import React, { useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  UncontrolledAlert
} from 'reactstrap';
import { confirmSignUp } from 'utils/auth';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Link, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { useTranslation } from 'react-i18next';

function ConfirmSignUp(props) {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { username: userName } = qs.parse(search);

  const defaultValues = {
    username: userName,
    code: ''
  };

  const [values, setValues] = useState(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmError, setConfirmError] = useState(null);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleOnSubmit = async e => {
    e.preventDefault();
    const { username, code } = values;
    const { history } = props;

    setIsLoading(true);
    try {
      await confirmSignUp(username, code);
      setIsLoading(false);
      setConfirmError(null);
      history.push('/login');
      toast.success(t('signup.confirm_sign_up_success'));
    } catch (error) {
      setIsLoading(false);
      setConfirmError(error);
    }
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            {confirmError && (
              <UncontrolledAlert color="danger" fade={false}>
                <span className="alert-inner--icon">
                  <i className="ni ni-circle-08" />
                </span>{' '}
                {confirmError.message}
              </UncontrolledAlert>
            )}

            <div className="text-center text-muted mb-4">
              <small>{t('signup.confirm_registration')}</small>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-circle-08" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    id="username"
                    name="username"
                    placeholder={t('signup.username_placeholder')}
                    type="text"
                    value={values.username}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    id="code"
                    name="code"
                    placeholder={t('signup.code_placeholder')}
                    type="text"
                    value={values.code}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </FormGroup>

              <div className="text-center">
                <Button
                  className="my-4"
                  color="primary"
                  type="submit"
                  onClick={handleOnSubmit}
                  disabled={isLoading}
                >
                  {isLoading ? t('signup.confirming') : t('signup.confirm')}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <Link
              to={{
                pathname: `/forgot-password`
              }}
            >
              <small>{t('signup.forgot_password')}?</small>
            </Link>
          </Col>
          <Col className="text-right" xs="6">
            <Link
              to={{
                pathname: `/register`
              }}
            >
              <small>{t('signup.create_new_account')}?</small>
            </Link>
          </Col>
        </Row>
      </Col>
    </>
  );
}

ConfirmSignUp.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default ConfirmSignUp;
