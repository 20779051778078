import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  NavItem,
  NavLink,
  Nav,
  Row,
  CardBody
} from 'reactstrap';
import Chart from 'chart.js';
import { Line } from 'react-chartjs-2';
import Skeleton from 'react-loading-skeleton';
import classnames from 'classnames';
import { chartOptions, parseOptions, chartExample1 } from 'variables/charts';
import { useTranslation } from 'react-i18next';

function StatsChart({ title, subtitle, graphData, isLoading }) {
  const { t } = useTranslation();

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const [activeGroupName, setActiveGroupName] = useState(
    graphData[0].groupName
  );
  const activeGroup = graphData.find(
    group => group.groupName === activeGroupName
  );

  const toggleDataset = label => {
    setActiveGroupName(label);
  };

  return (
    <Card className="bg-gradient-default shadow">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h6 className="text-uppercase text-light ls-1 mb-1">
              {isLoading ? <Skeleton /> : subtitle}
            </h6>
            <h2 className="text-white mb-0">
              {isLoading ? <Skeleton /> : title}
            </h2>
          </div>
          <div className="col">
            <Nav className="justify-content-end" pills>
              {graphData.map(({ groupName }) => (
                <NavItem key={groupName}>
                  <NavLink
                    className={classnames('py-2 px-3', {
                      active: activeGroupName === groupName
                    })}
                    href="#"
                    onClick={() => toggleDataset(groupName)}
                  >
                    <span className="d-none d-md-block">
                      {t(`stats_chart.group_names.${groupName}`)}
                    </span>
                    <span className="d-md-none">
                      {t(`stats_chart.group_names.${groupName}`)}
                    </span>
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </div>
        </Row>
      </CardHeader>
      <CardBody>
        <div className="chart">
          <Line
            data={{
              labels: activeGroup.labels,
              datasets: activeGroup.datasets
            }}
            options={chartExample1.options}
          />
        </div>
      </CardBody>
    </Card>
  );
}

StatsChart.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  graphData: PropTypes.arrayOf(
    PropTypes.shape({
      groupName: PropTypes.string.isRequired,
      datasets: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          data: PropTypes.arrayOf(PropTypes.number)
        })
      ).isRequired
    })
  ).isRequired
};

StatsChart.defaultProps = {
  isLoading: false
};

export default StatsChart;
