import { Auth } from 'aws-amplify';

const clearLocalStorage = () => {
  Object.keys(localStorage)
    .filter(key => key.includes('CognitoIdentityServiceProvider'))
    .forEach(k => localStorage.removeItem(k));
};

export const login = async (username, password) => {
  const user = await Auth.signIn(username, password);
  return user;
};

export const confirmLoginMFA = async (user, code) => {
  await Auth.confirmSignIn(user, code, 'SOFTWARE_TOKEN_MFA');
};

export const logout = async () => {
  await Auth.signOut();
  clearLocalStorage();
};

export const forgotPassword = async username => {
  await Auth.forgotPassword(username);
};

export const forgotPasswordSubmit = async (username, code, newPassword) => {
  await Auth.forgotPasswordSubmit(username, code, newPassword);
};

export const signUp = async (username, email, password, phone) => {
  const user = await Auth.signUp({
    username,
    password,
    attributes: {
      email,
      phone_number: phone
    }
  });
  return user;
};

export const confirmSignUp = async (username, code) => {
  await Auth.confirmSignUp(username, code);
};
