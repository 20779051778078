function getFilename(s) {
  const tokens = s.split('/');
  if (tokens.lenght === 0) {
    throw new Error(`unable to get file name from ${s}`);
  }

  return tokens[tokens.length - 1];
}

export default getFilename;
