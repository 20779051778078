import React, { useState } from 'react';
import { Button, Card, CardBody, Col, UncontrolledAlert } from 'reactstrap';
import { logout } from 'utils/auth';
import PropTypes from 'prop-types';
import useAmplifyAuth from 'utils/useAmplifyAuth';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function Logout(props) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const [logoutError, setLogoutError] = useState(null);
  const {
    state: { user }
  } = useAmplifyAuth();
  const isLoggedIn = !!user;
  const { history } = props;

  const handleOnSubmit = async () => {
    setIsLoading(true);
    try {
      await logout();
      setIsLoading(false);
      setIsLoggedOut(true);
      setLogoutError(null);
      toast.success(t('logout.logout_success'));
      history.push({
        pathname: '/login'
      });
    } catch (error) {
      toast.error(t('logout.logout_failure', { error }));
      setIsLoading(false);
      setLogoutError(error);
    }
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            {logoutError && (
              <UncontrolledAlert color="danger" fade={false}>
                <span className="alert-inner--icon">
                  <i className="ni ni-circle-08" />
                </span>{' '}
                {t('logout.logout_failure')}
              </UncontrolledAlert>
            )}
            {isLoggedOut && (
              <UncontrolledAlert color="success" fade={false}>
                <span className="alert-inner--icon">
                  <i className="ni ni-circle-08" />
                </span>{' '}
                {t('logout.logout_success')}
              </UncontrolledAlert>
            )}
            <div className="text-center text-muted mb-4">
              {isLoggedIn ? (
                <small>{t('logout.do_you_want_to_logout')}</small>
              ) : (
                <small>{t('logout.you_are_not_logged_in')}</small>
              )}
            </div>
            <div className="text-center">
              {isLoggedIn ? (
                <Button
                  className="my-4"
                  color="primary"
                  type="button"
                  onClick={handleOnSubmit}
                  disabled={isLoading || !isLoggedIn}
                >
                  {isLoading ? t('logout.logging_out') : t('logout.log_out')}
                </Button>
              ) : (
                <Button
                  className="my-4"
                  color="primary"
                  type="button"
                  onClick={() => {
                    history.push('/login');
                  }}
                >
                  {t('logout.log_me_in')}
                </Button>
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
}

Logout.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default Logout;
