import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Button } from 'reactstrap';
import ImageWithHighlightedArea from 'components/ImageWithHighlightedArea';
import FormattedPercentage from 'components/FormattedPercentage';
import loadS3Image from 'utils/loadS3Image';
import modelResultsToHighlightedAreas from 'utils/modelResultsToHighlightedAreas';
import { useTranslation } from 'react-i18next';

function ImageWithBoundingBoxes({ photo, modelResults }) {
  const { t } = useTranslation();
  const [imageUrl, setImageUrl] = useState(null);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const highlightedAreas = modelResultsToHighlightedAreas(modelResults);

  const handleHighlightedAreaToggle = id => {
    const idx = selectedAreas.findIndex(i => i === id);
    if (idx === -1) {
      setSelectedAreas(prev => [...prev, id]);
    } else {
      setSelectedAreas(prev => prev.filter(i => i !== id));
    }
  };

  const handleShowToggle = () => {
    if (selectedAreas.length === highlightedAreas.length) {
      setSelectedAreas([]);
    } else {
      setSelectedAreas(highlightedAreas.map(i => i.id));
    }
  };

  const visibleAreas = highlightedAreas.filter(ha =>
    selectedAreas.includes(ha.id)
  );

  useEffect(() => {
    loadS3Image(photo.key.replace('public/', '')).then(url => setImageUrl(url));
  }, [photo, setImageUrl]);

  useEffect(() => {
    setSelectedAreas([]);
  }, [photo]);

  return (
    <div className="modal-body modal-max-width modal-80-width pl-5 pr-5">
      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <div className="custom-control custom-control-alternative custom-checkbox mb-3">
              <input
                className="custom-control-input"
                id="customCheck5"
                type="checkbox"
                checked={
                  visibleAreas.length === highlightedAreas.length &&
                  highlightedAreas.length > 0
                }
                disabled={highlightedAreas.length < 1}
                onClick={() => handleShowToggle()}
              />
              <label className="custom-control-label" htmlFor="customCheck5">
                {t('show bounding boxes')}
              </label>
            </div>
            <div>
              <Link
                to={{
                  pathname: `/public-image?src=${btoa(imageUrl)}`
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="link">{t('open in new tab')}</span>
              </Link>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {highlightedAreas.map(area => (
            <Button
              type="button"
              className="mb-2 ml-0"
              onClick={() => handleHighlightedAreaToggle(area.id)}
              style={{
                backgroundColor:
                  selectedAreas.includes(area.id) && area.strokeColor
              }}
            >
              {area.name} <FormattedPercentage value={area.percentage} />
            </Button>
          ))}
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <Card className="shadow">
            {imageUrl && (
              <ImageWithHighlightedArea
                imageUrl={imageUrl}
                originalImageWidth={photo.width}
                originalImageHeight={photo.height}
                highlightedAreas={visibleAreas}
              />
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
}

ImageWithBoundingBoxes.propTypes = {
  photo: PropTypes.shape({
    key: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired
  }).isRequired,
  modelResults: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      modelFamily: PropTypes.string.isRequired,
      results: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          score: PropTypes.number.isRequired,
          box: PropTypes.shape({
            x1: PropTypes.number.isRequired,
            y1: PropTypes.number.isRequired,
            x2: PropTypes.number.isRequired,
            y2: PropTypes.number.isRequired
          })
        })
      )
    })
  ).isRequired
};

export default ImageWithBoundingBoxes;
