import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const sessionContext = createContext();

function useProvideSession() {
  const [sessionState, setSessionState] = useState({});

  const setSessionVariable = (key, value) => {
    setSessionState({
      ...sessionState,
      [key]: value
    });
  };

  const sessionVariable = key => {
    return sessionState[key];
  };

  return {
    setSessionVariable,
    sessionVariable
  };
}

export function SessionProvider({ children }) {
  const session = useProvideSession();
  return (
    <sessionContext.Provider value={session}>
      {children}
    </sessionContext.Provider>
  );
}

SessionProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export const useSession = () => {
  return useContext(sessionContext);
};
