function hasRegionsForAnnotation(c, sources) {
  // check if at least 1 photo available
  if (!Array.isArray(c?.photos)) {
    return false;
  }

  if (!c.source || !sources) {
    // not possible to decide
    return false;
  }

  const thisCaseSources = sources.filter(s => s.filterName === c.source);
  if (thisCaseSources.length === 0) {
    // there is no source in config
    return false;
  }
  // if there is more than one (shouldn't be), take first one
  const thisCaseSource = thisCaseSources[0];

  // if there is no annotationConfig OR doesn't contain models, not possible to decide
  if (
    !thisCaseSource.annotationConfig ||
    !thisCaseSource.annotationConfig.models ||
    !thisCaseSource.annotationConfig.models.length
  ) {
    return false;
  }

  const extractionModels = thisCaseSource.annotationConfig.models;

  // eslint-disable-next-line no-restricted-syntax
  for (const photo of c.photos) {
    const extractionData = photo?.modelResults?.v1?.find(mr =>
      extractionModels.includes(mr.name)
    );
    if (extractionData?.results?.length > 0) {
      return true;
    }
  }

  return false;
}

export default hasRegionsForAnnotation;
