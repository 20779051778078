import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import AddCaseButton from 'components/AddCaseButton';
import CaseFilter from 'components/CaseFilter';

function CasesHeader({ onCaseAdded }) {
  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            <Row className="pb-4">
              <Col>
                <AddCaseButton onCaseAdded={onCaseAdded} />
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <CaseFilter />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

CasesHeader.propTypes = {
  onCaseAdded: PropTypes.func.isRequired
};

export default CasesHeader;
