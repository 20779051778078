import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Check from 'components/Check';

function splitLayout(layout) {
  return layout.split('.');
}

function SummaryV2ColumnValue({ type, value }) {
  const { t } = useTranslation();

  if (value === null || value === undefined) {
    return <span>-</span>;
  }

  switch (type) {
    case 'name':
      return <span>{t(`summary.${value}`)}</span>;
    case 'label':
      return <span>{t(`summary.${value}`, value.replaceAll('_', ' '))}</span>;
    case 'count':
      return parseInt(value, 10);
    case 'score':
      return `${(parseFloat(value) * 100).toFixed(2)} %`;
    case 'check':
      if (value === true) {
        return <Check checked />;
      }
      return <Check checked={false} />;
    default:
      return '';
  }
}

function SummaryV2Row({ row }) {
  const { layout, ...columns } = row;
  const columnTypes = splitLayout(layout);
  const columnValues = columnTypes.map(ct => ({
    type: ct,
    value: columns[ct]
  }));
  return (
    <tr>
      {columnValues.map(({ type, value }) => (
        <td>
          <SummaryV2ColumnValue type={type} value={value} />
        </td>
      ))}
    </tr>
  );
}

SummaryV2Row.propTypes = {
  row: PropTypes.shape({
    layout: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    count: PropTypes.number,
    score: PropTypes.number,
    check: PropTypes.bool
  }).isRequired
};

export default SummaryV2Row;
