import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import ImageGalleryItem from 'components/ImageGalleryItem';

function sanitize({ photoSet, photo }) {
  return {
    id: photo.id,
    thumbnailPath: photo.thumbnail.key,
    valid: photoSet.verified,
    imageTitle: photoSet.label
  };
}

function ImageGallery({ gallery, selectedIndex, handleClick }) {
  return (
    <Row>
      <Col className="d-flex flex-row flex-wrap justify-content-center">
        {gallery
          .map(i => sanitize(i))
          .map((i, idx) => (
            <ImageGalleryItem
              imageKey={i.id}
              thumbnailPath={i.thumbnailPath}
              valid={i.valid}
              imageTitle={i.imageTitle}
              s3
              onClick={() => handleClick(idx)}
              active={idx === selectedIndex}
            />
          ))}
      </Col>
    </Row>
  );
}

ImageGallery.propTypes = {
  gallery: PropTypes.arrayOf(
    PropTypes.shape({
      photoSet: PropTypes.shape(),
      photos: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          createdAt: PropTypes.string.isRequired,
          fullsize: PropTypes.shape({
            key: PropTypes.string.isRequired,
            width: PropTypes.number.isRequired,
            height: PropTypes.number.isRequired
          }).isRequired,
          thumbnail: PropTypes.shape({
            key: PropTypes.string.isRequired,
            width: PropTypes.number.isRequired,
            height: PropTypes.number.isRequired
          }).isRequired
        })
      )
    })
  ).isRequired,
  selectedIndex: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired
};

export default ImageGallery;
