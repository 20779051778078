import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Form
} from 'reactstrap';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useAccount } from 'utils/useAccount';
import { v4 } from 'uuid';
import picture from 'assets/img/brand/SEARPENT_animated_150px.gif';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import * as qs from 'query-string';
import selectSource from 'utils/selectSource';

const CREATE_CASE = gql`
  mutation createCase($input: CreateAlbumInput!) {
    createAlbum(input: $input) {
      id
      name
      createdAt
      status
      summary {
        iscar
        model {
          label
        }
        color {
          label
        }
        exifstatus
        allviews
      }
      feedback {
        casecheck
        exiferror
        modelerror
      }
    }
  }
`;

function AddCaseButton({ onCaseAdded, disabled }) {
  const { t } = useTranslation();
  const { sources } = useAccount();
  const { search } = useLocation();
  const { source: urlSource } = qs.parse(search);

  // modal controls
  const [modalOpen, setModalOpen] = useState(false);

  const caseSources = sources ? sources.filter(s => !s.hasInspections) : [];

  // input controls
  const defaultValues = {
    id: v4(),
    source: selectSource(caseSources, urlSource),
    name: ''
  };

  const [values, setValues] = useState(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const [, setCreateExportError] = useState(null);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const resetValues = () => {
    setValues(defaultValues);
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    resetValues();
  };

  const handleCaseAdded = () => {
    onCaseAdded();
  };

  const [createCase] = useMutation(CREATE_CASE);

  const handleOnSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await createCase({
        variables: { input: values }
      });
      setIsLoading(false);
      setCreateExportError(null);
      setModalOpen(false);
      toast.success(t('case.create_case_success'));
      handleCaseAdded();
    } catch (error) {
      setIsLoading(false);
      setCreateExportError(error);
      toast.success(t('case.create_case_failure', { error }));
    }
  };

  return (
    <>
      <Button color="success" onClick={toggleModal} disabled={disabled}>
        {t('case.new_case')}
      </Button>
      {modalOpen && (
        <Modal
          className="modal-dialog-centered"
          isOpen={modalOpen}
          toggle={toggleModal}
          autoFocus={false}
        >
          <Form onSubmit={handleOnSubmit}>
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {t('case.create_case')}
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={toggleModal}
              >
                <span aria-hidden>×</span>
              </button>
            </div>
            <div className="modal-body">
              <Row>
                <Col>
                  <FormGroup>
                    <Label className="form-control-Label" htmlFor="name">
                      {t('case.name')}
                    </Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      onChange={handleInputChange}
                      value={values.name}
                      autoFocus
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label className="form-control-Label" htmlFor="source">
                      {t('case.source')}
                    </Label>
                    <Input
                      type="select"
                      name="source"
                      id="source"
                      onChange={handleInputChange}
                      value={values.source}
                    >
                      {caseSources &&
                        caseSources.map(exportSource => (
                          <option
                            value={exportSource.filterName}
                            key={exportSource.filterName}
                          >
                            {exportSource.name}
                          </option>
                        ))}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div className="modal-footer">
              <Button
                color="secondary"
                data-dismiss="modal"
                type="button"
                onClick={toggleModal}
              >
                {t('general.cancel')}
              </Button>
              <Button
                color="success"
                disabled={isLoading}
                type="button"
                onClick={handleOnSubmit}
              >
                {isLoading && (
                  <img
                    src={picture}
                    alt="loading"
                    style={{ height: '2rem' }}
                    className="mr-2"
                  />
                )}
                {!isLoading ? t('case.create_case') : t('case.creating_case')}
              </Button>
            </div>
          </Form>
        </Modal>
      )}
    </>
  );
}

AddCaseButton.propTypes = {
  onCaseAdded: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

AddCaseButton.defaultProps = {
  disabled: false
};

export default AddCaseButton;
