import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import SendInvitationButton from 'components/SendInvitationButton';
import InspectionFilter from 'components/InspectionFilter';

function InspectionsHeader({ onSMSSent }) {
  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            <Row className="pb-4">
              <Col>
                <SendInvitationButton onSMSSent={onSMSSent} />
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <InspectionFilter />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

InspectionsHeader.propTypes = {
  onSMSSent: PropTypes.func.isRequired
};

export default InspectionsHeader;
