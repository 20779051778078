import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, CardTitle, Col } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import classnames from 'classnames';
import * as _ from 'lodash';
import numberToPercentage from 'utils/numberToPercentage';
import percentageDelta from 'utils/percentageDelta';
import numberToFixed from 'utils/numberToFixed';
import { useTranslation } from 'react-i18next';

function StatsCard({
  title,
  value,
  previousValue,
  isPercentage,
  iconClassName,
  iconBackgroundColor,
  isLoading
}) {
  const { t } = useTranslation();
  const formattedValue = isPercentage
    ? `${numberToPercentage(value)} %`
    : value;

  const change = percentageDelta(value, previousValue);

  return (
    <Card className="card-stats mb-4 mb-xl-0">
      <CardBody>
        <Row>
          <div className="col">
            <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
              {isLoading ? <Skeleton /> : title}
            </CardTitle>
            <span className="h2 font-weight-bold mb-0">
              {isLoading ? <Skeleton /> : formattedValue}
            </span>
          </div>
          <Col className="col-auto">
            {isLoading ? (
              <Skeleton circle height={50} width={50} />
            ) : (
              <div
                className={`icon icon-shape ${iconBackgroundColor} text-white rounded-circle shadow`}
              >
                <i className={iconClassName} />
              </div>
            )}
          </Col>
        </Row>
        {isLoading ? (
          <Skeleton />
        ) : (
          <p className="mt-3 mb-0 text-muted text-sm">
            <span
              className={classnames('mr-2', {
                'text-success': change > 0,
                'text-danger': change < 0
              })}
            >
              <i
                className={classnames(
                  'fa ',
                  {
                    'fa-arrow-up': change > 0,
                    'fa-arrow-right': change === 0,
                    'fa-arrow-down': change < 0
                  },
                  'mr-1'
                )}
              />
              {_.isFinite(change)
                ? `${numberToFixed(change)} %`
                : t('general.NA')}
            </span>{' '}
            <span className="text-nowrap">
              {t('general.change_since_last_week')}
            </span>
          </p>
        )}
      </CardBody>
    </Card>
  );
}

StatsCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number,
  previousValue: PropTypes.number,
  isPercentage: PropTypes.bool,
  isLoading: PropTypes.bool,
  iconClassName: PropTypes.string,
  iconBackgroundColor: PropTypes.string
};

StatsCard.defaultProps = {
  isPercentage: false,
  isLoading: false,
  iconClassName: 'far fa-chart-bar',
  iconBackgroundColor: 'bg-info',
  value: null,
  previousValue: null
};

export default StatsCard;
