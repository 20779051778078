import React from 'react';
import { Card, CardHeader, Container, Row } from 'reactstrap';
import InspectionsHeader from 'components/Headers/InspectionsHeader';
import InspectionsTable from 'components/Tables/InspectionsTable';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Loader from 'components/Loader';
import { useTranslation } from 'react-i18next';
import addTimeToDates from 'utils/addTimeToDates';
import { useURLFilter } from 'utils/useFilter';

const LIST_INSPECTIONS = gql`
  query listInspections($filter: ListInspectionsInput!) {
    listInspections(filter: $filter) {
      items {
        id
        name
        createdAt
        status
        summary {
          iscar
          model {
            label
          }
          color {
            label
          }
          exifstatus
          allviews
        }
        feedback {
          casecheck
          exiferror
          modelerror
        }
      }
    }
  }
`;

function normalizeCases(graphCases) {
  const tableCases = graphCases.map(c => {
    // Tome, tohle je hnusny quick fix, aby mi sel FE :D
    if (c.summary) {
      return {
        id: c.id,
        name: c.name,
        createdAt: c.createdAt,
        status: c.status,
        isCar: c.summary.iscar ? c.summary.iscar : null,
        carModel: c.summary.model.label ? c.summary.model.label : null,
        color: c.summary.color.label ? c.summary.color.label : null,
        exifStatus: c.summary.exifstatus ? c.summary.exifstatus : null,
        views: c.summary.allviews ? c.summary.allviews : null,
        feedback: c.feedback
      };
    }

    return {
      id: c.id,
      name: c.name,
      createdAt: c.createdAt,
      status: c.status,
      isCar: null,
      carModel: null,
      color: null,
      exifStatus: null,
      views: null,
      feedback: c.feedback
    };
  });
  return tableCases;
}

function Inspections() {
  const { t } = useTranslation();
  const { filter } = useURLFilter();
  const { data, loading, error, refetch } = useQuery(LIST_INSPECTIONS, {
    variables: {
      filter: addTimeToDates(filter)
    }
  });

  if (error) return <h1>GQL error...</h1>;

  return (
    <>
      <InspectionsHeader onSMSSent={refetch} />
      <Container className="mt--7" fluid>
        <Row className="mb-4">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex justify-content-between">
                <h3 className="mb-0">{t('general.inspections')}</h3>
              </CardHeader>
              {loading && <Loader title="loading data" />}
              {data && (
                <InspectionsTable
                  items={normalizeCases(data.listInspections.items)}
                />
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default Inspections;
