import React from 'react';
import { Badge } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function isBool(variable) {
  if (typeof variable === 'boolean') {
    return true;
  }
  return false;
}

function FeedbackBadgeGroup({ feedback }) {
  const { t } = useTranslation();

  return (
    <>
      {isBool(feedback.casecheck) && (
        <Badge
          className="mr-1"
          color={feedback.casecheck === true ? 'success' : 'secondary'}
        >
          {t('case.table.feedbacks.case_check')}
        </Badge>
      )}
      {isBool(feedback.modelerror) && (
        <Badge
          className="mr-1"
          color={feedback.modelerror === true ? 'danger' : 'secondary'}
        >
          {t('case.table.feedbacks.model_error')}
        </Badge>
      )}
      {isBool(feedback.exiferror) && (
        <Badge
          className="mr-1"
          color={feedback.exiferror === true ? 'danger' : 'secondary'}
        >
          {t('case.table.feedbacks.exif_error')}
        </Badge>
      )}
    </>
  );
}

FeedbackBadgeGroup.propTypes = {
  feedback: PropTypes.shape({
    casecheck: PropTypes.bool.isRequired,
    exiferror: PropTypes.bool.isRequired,
    modelerror: PropTypes.bool.isRequired
  }).isRequired
};

export default FeedbackBadgeGroup;
