import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import photosToImages from 'utils/photosToImages';
import Annotator from '@searpent/react-image-annotate';
import regionsToModelResults from 'utils/regionsToModelResults';
import { useAccount } from 'utils/useAccount';
import defaultClsColors from 'variables/defaultClsColors';
import hasRegionsForAnnotation from 'utils/hasRegionsForAnnotation';
import FullscreenInfo from 'components/FullscreenInfo';
import { HotKeys } from 'react-hotkeys';
import Loader from '../components/Loader';
import ItemFailedToLoad from '../components/ItemFailedToLoad';
import { defaultKeyMap } from '../components/Annotation/ShortcutsManager.js';

const GET_ALBUM_DETAIL = gql`
  query getAlbum($id: ID!) {
    getAlbum(id: $id) {
      id
      source
      name
      metadata {
        key
        value
      }
      photos {
        id
        createdAt
        thumbnail {
          height
          width
          key
        }
        fullsize {
          height
          width
          key
        }
        modelResults {
          v1 {
            name
            modelFamily
            results {
              id
              label
              score
              box {
                x1: X1
                x2: X2
                y1: Y1
                y2: Y2
              }
              groupId
              text
            }
          }
        }
        editedModelResults {
          v1 {
            name
            modelFamily
            results {
              id
              label
              score
              box {
                x1: X1
                x2: X2
                y1: Y1
                y2: Y2
              }
              groupId
              text
            }
          }
        }
        metadata {
          key
          value
        }
      }
    }
  }
`;

const UPDATE_EDITED_MODEL_RESULTS = gql`
  mutation updateEditedModelResults(
    $id: String!
    $albumId: String!
    $editedModelResults: ModelResultsVersionInput!
  ) {
    updateEditedModelResults(
      input: {
        id: $id
        albumId: $albumId
        editedModelResults: $editedModelResults
      }
    ) {
      id
    }
  }
`;

function Annotation() {
  const { id } = useParams();
  const { t } = useTranslation();
  const { modelClasses, sources } = useAccount();
  const [annotatorData, setAnnotatorData] = useState(undefined);
  const [extractionModelName, setExtractionModelName] = useState('');
  const { loading, error, data } = useQuery(GET_ALBUM_DETAIL, {
    variables: { id }
  });
  const [updateEditedModelResults] = useMutation(UPDATE_EDITED_MODEL_RESULTS);

  const updateImage = async image => {
    const albumId = id;
    const photoId = image.id;
    const modelResults = regionsToModelResults(image.regions);
    const { metadata } = image;

    const variables = {
      id: photoId,
      albumId,
      editedModelResults: {
        v1: [
          {
            modelFamily: extractionModelName,
            name: extractionModelName,
            results: modelResults
          }
        ]
      }
    };
    await updateEditedModelResults({
      variables
    });

    // searpent.atlassian.net / browse / SI - 2051;
    console.log('[WIP] updating metadata on BE: ', metadata);
  };

  const handleSave = async images => {
    images.forEach(image => {
      updateImage(image);
    });
  };

  useEffect(() => {
    if (!data) return;

    const parsePhotos = async (photos, srcs) => {
      const source = srcs.find(s => s.filterName === data.getAlbum.source);
      if (!source?.annotationConfig?.models) {
        return;
      }
      const parsedData = await photosToImages(
        photos,
        source.annotationConfig.models
      );
      setAnnotatorData(parsedData);

      const modelName = source.annotationConfig.models?.[0];
      setExtractionModelName(modelName);
    };

    parsePhotos(data.getAlbum.photos, sources);
  }, [data, sources]);

  const annotationClasses = modelClasses(extractionModelName);
  const clsColors = annotationClasses
    ? annotationClasses.reduce((prev, curr) => {
        return {
          ...prev,
          [curr.name]: curr.color
        };
      }, {})
    : defaultClsColors;

  if (loading) return <Loader title={t('general.loading_data')} />;
  if (error)
    return <ItemFailedToLoad error={new Error('failed to load data')} />;
  if (!hasRegionsForAnnotation(data.getAlbum, sources)) {
    return <FullscreenInfo message="case.not_calculated" />;
  }
  if (annotatorData)
    return (
      <HotKeys keyMap={defaultKeyMap}>
        <Annotator
          labelImages
          regionClsList={[
            'author',
            'appendix',
            'photo_author',
            'photo_caption',
            'advertisement',
            'other_graphics',
            'unknown',
            'title',
            'about_author',
            'image',
            'subtitle',
            'interview',
            'table',
            'text'
          ]}
          onImagesChange={images => console.log('[images changed to]:', images)}
          images={annotatorData}
          clsColors={clsColors}
          // groupColors={[
          //   '#e3a7c0',
          //   '#c2d5a8',
          //   '#f2e9cc',
          //   '#bad5f0',
          //   '#f0d5ba',
          //   '#d6eff5',
          //   '#f8d7e8',
          //   '#a5d5d5',
          //   '#b0abcb',
          //   '#fae4cc'
          // ]}
          enabledTools={[]}
          groupColors={['#343434', '#989898', '#dcdcdc']}
          onGroupSelect={groupId => console.log('selected groupid:', groupId)}
          hideHeader
          hideHistory
          hideNotEditingLabel
          showEditor
          showPageSelector
          metadata={annotatorData?.metadata || []}
          onSave={handleSave}
          onRecalc={() => console.log('[onRecalc] triggered]')}
          onExit={s => console.log('[onExit] triggered:', s)}
          help={`# Tools\n\n**E** - select tool\n\n**B** - frame tool\n\n**Ctrl** + **click frame** - edit frame\n\n**1**- **9** - change class`}
        />
      </HotKeys>
    );
  return null;
}

export default Annotation;
