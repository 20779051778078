import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { NavLink as NavLinkRRD } from 'react-router-dom';
import useRoutes from 'utils/useRoutes';
import qs from 'query-string';
import { useTranslation } from 'react-i18next';

function SideNavbar() {
  const { t } = useTranslation();
  const { routes } = useRoutes();

  return (
    <Nav navbar>
      {routes &&
        routes.map(route => (
          <div key={route.path}>
            <NavItem>
              <NavLink
                disabled={route.path.startsWith("disabled-") ? true : false}
                exact
                to={route.path}
                tag={NavLinkRRD}
                activeStyle={{
                  fontWeight: 'bold',
                  backgroundColor: '#f7fafc'
                }}
              >
                <i className={route.icon} />
                {t(`routes.${route.title.toLowerCase()}`)}
              </NavLink>
            </NavItem>
            {route.children &&
              route.children.map(subroute => (
                <NavItem key={subroute.path}>
                  <NavLink
                    to={subroute.path}
                    tag={NavLinkRRD}
                    activeStyle={{
                      fontWeight: 'bold',
                      backgroundColor: '#f7fafc'
                    }}
                    style={{
                      paddingLeft: '2rem',
                      fontStyle: 'italic'
                    }}
                    isActive={(match, location) => {
                      if (!match) {
                        return false;
                      }

                      const subrouteSearchParams = qs.parse(
                        subroute.path.split('?')[1]
                      );
                      const currentLocationSearchParams = qs.parse(
                        location.search
                      );

                      if (
                        subrouteSearchParams.source ===
                        currentLocationSearchParams.source
                      ) {
                        return true;
                      }
                      return false;
                    }}
                  >
                    {subroute.title}
                  </NavLink>
                </NavItem>
              ))}
          </div>
        ))}
    </Nav>
  );
}

SideNavbar.propTypes = {};

export default SideNavbar;
