// WARNING: DO NOT EDIT. This file is automatically generated and will be overwritten.
// Environment: prod
// Insurance name: axaczskdev
const awsmobile = {
"aws_project_region": "eu-west-1",
"aws_user_files_s3_bucket_region": "eu-west-1",
"aws_cognito_region": "eu-west-1",
"aws_content_delivery_bucket_region": "eu-west-1",
"aws_appsync_region": "eu-west-1",
"aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
"aws_appsync_graphqlEndpoint": "https://cmhkbq3za5hupgixey34dpn6ne.appsync-api.eu-west-1.amazonaws.com/graphql",
"aws_user_pools_id": "eu-west-1_tyvID2z4P",
"aws_content_delivery_url": "https://d3lqppedvz8s9z.cloudfront.net",
"aws_user_pools_web_client_id": "4fl31pu5qac1m1jraftgiselis",
"aws_user_files_s3_bucket": "axaczskdev-classy-storage",
"aws_cognito_identity_pool_id": "eu-west-1:cfac8bc4-eb63-4ab2-a708-3f6a351bd7ed",
"aws_content_delivery_bucket": "axaczskdev-classy-hosting"
};
export default awsmobile;
