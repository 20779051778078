import React from 'react';
import Loader from 'components/Loader';
import useAmplifyAuth from 'utils/useAmplifyAuth';
import { useTranslation } from 'react-i18next';

function FullscreenLoader() {
  const { t } = useTranslation();
  const {
    state: { isLoading }
  } = useAmplifyAuth();

  if (!isLoading) return null;

  return <Loader title={t('general.loading_account')} />;
}

export default FullscreenLoader;
