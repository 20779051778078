import React, { useState } from 'react';
import { Card, CardHeader, Table, Row, CardFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import numberToPercentage from 'utils/numberToPercentage';
import { useTranslation } from 'react-i18next';

function ModelResultsTable({
  modelFamilyName,
  icon,
  iconColor,
  modelResults,
  maxRows
}) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Card className="shadow">
      <CardHeader className="border-0">
        <Row className="align-items-center">
          <div className="col">
            <h3 className="mb-0">{modelFamilyName}</h3>
          </div>
          <div
            className={`icon icon-shape ${iconColor} text-white rounded-circle shadow mr-2`}
          >
            <i className={icon} />
          </div>
        </Row>
      </CardHeader>
      {modelResults.length === 0 && (
        <div className="mb-2">
          <h4 className="text-center">{t('general.no_results')}</h4>
        </div>
      )}
      {modelResults.length !== 0 && (
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">{t('general.label')}</th>
              <th scope="col">{t('general.score')}</th>
            </tr>
          </thead>
          <tbody>
            {modelResults.map((modelResult, i) => {
              if ((!isOpen && i < maxRows) || isOpen) {
                return (
                  <tr key={`${modelResult.label}-${modelResult.score}`}>
                    <th scope="row">{modelResult.label}</th>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">
                          {numberToPercentage(modelResult.score)}%
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              }
              return null;
            })}
          </tbody>
        </Table>
      )}
      <CardFooter>
        {modelResults.length > maxRows && (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <p
            className="card__collapse__toggle text-center"
            onClick={() => setIsOpen(!isOpen)}
          >
            {!isOpen ? t('general.show_more') : t('general.show_less')}
          </p>
        )}
      </CardFooter>
    </Card>
  );
}

ModelResultsTable.propTypes = {
  modelFamilyName: PropTypes.string.isRequired,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  modelResults: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      score: PropTypes.number.isRequired
    })
  ).isRequired,
  maxRows: PropTypes.number
};

ModelResultsTable.defaultProps = {
  icon: 'fas fa-car',
  iconColor: 'bg-info',
  maxRows: 4
};

export default ModelResultsTable;
