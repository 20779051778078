import React, { useState, useEffect } from 'react';
import Chart from 'chart.js';
import { Container, Row, Col } from 'reactstrap';
import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import moment from 'moment';
import { useAccount } from 'utils/useAccount';
import StatsChart from 'components/StatsChart';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
// core components
import { chartOptions, parseOptions } from 'variables/charts';

import DashboardHeader from 'components/Headers/DashboardHeader';

const GET_DASHBOARD_DATA = gql`
  query getDashboardData(
    $thisWeekFilter: ListStatisticsInput!
    $lastWeekFilter: ListStatisticsInput!
  ) {
    thisWeek: listStatistics(filter: $thisWeekFilter) {
      items {
        id
        createdAt
        dayTs
        stats {
          albumCount
          allViewsCount
          caseCheckCount
          statusAndCaseCheckedCount
        }
      }
    }
    lastWeek: listStatistics(filter: $lastWeekFilter) {
      items {
        id
        createdAt
        dayTs
        stats {
          albumCount
          allViewsCount
          caseCheckCount
          statusAndCaseCheckedCount
        }
      }
    }
  }
`;
function calculateWeekStatistics(data) {
  let cases = 0;
  let notOkCases = 0;

  // eslint-disable-next-line no-restricted-syntax
  for (const day of data) {
    cases += day.stats.albumCount;
    notOkCases += day.stats.albumCount - day.stats.allViewsCount;
  }

  return {
    cases,
    dailyAverageCases: cases / data.length,
    notOkCases
  };
}

// group contains groupName, labels, datasets
function calculateGraphDataAllGroup(data) {
  // groupName
  const groupName = 'all';

  // labels
  const labels = [];

  // datasets
  const casesLabel = i18next.t('dashboard.all_cases');
  const casesValue = [];

  const notOksLabel = i18next.t('dashboard.not_ok_cases');
  const notOksBorderColor = 'rgba(255, 0, 0, 1)';
  const notOksValues = [];

  // populate values to datasets
  // eslint-disable-next-line no-restricted-syntax
  for (const day of data) {
    labels.push(
      moment(day.dayTs)
        .utc()
        .format('DD.MM.YYYY')
    );
    casesValue.push(day.stats.albumCount);
    notOksValues.push(day.stats.albumCount - day.stats.allViewsCount);
  }

  return {
    groupName,
    labels,
    datasets: [
      { label: casesLabel, data: casesValue },
      { label: notOksLabel, data: notOksValues, borderColor: notOksBorderColor }
    ]
  };
}

function calculateGraphDataCheckedGroup(data) {
  // groupName
  const groupName = 'checked';

  // labels
  const labels = [];

  // datasets
  const checkedCasesLabel = 'all checked cases';
  const checkedCasesValue = [];

  const checkedNotOksLabel = 'checked not OK cases';
  const checkedNotOksBorderColor = 'rgba(255, 0, 0, 1)';
  const checkedNotOksValues = [];

  // populate values to datasets
  // eslint-disable-next-line no-restricted-syntax
  for (const day of data) {
    labels.push(
      moment(day.dayTs)
        .utc()
        .format('DD.MM.YYYY')
    );
    checkedCasesValue.push(day.stats.caseCheckCount);
    checkedNotOksValues.push(
      day.stats.caseCheckCount - day.stats.statusAndCaseCheckedCount
    );
  }

  return {
    groupName,
    labels,
    datasets: [
      { label: checkedCasesLabel, data: checkedCasesValue },
      {
        label: checkedNotOksLabel,
        data: checkedNotOksValues,
        borderColor: checkedNotOksBorderColor
      }
    ]
  };
}

function calculateStatisticsData(data) {
  // this week stats
  const thisWeek = calculateWeekStatistics(data.thisWeek.items);

  // last week
  const lastWeek = calculateWeekStatistics(data.lastWeek.items);

  // graph data
  const graphAll = calculateGraphDataAllGroup(data.thisWeek.items);
  const graphChecked = calculateGraphDataCheckedGroup(data.thisWeek.items);

  return {
    thisWeek,
    lastWeek,
    graphAll,
    graphChecked
  };
}

function Dashboard() {
  const { t } = useTranslation();
  // calculate time window for dashboard
  const thisWeekTo = moment()
    .endOf('day')
    .toISOString();
  const thisWeekFrom = moment()
    .subtract(6, 'days')
    .startOf('day')
    .toISOString();
  const lastWeekTo = moment()
    .subtract(7, 'days')
    .endOf('day')
    .toISOString();
  const lastWeekFrom = moment()
    .subtract(13, 'days')
    .startOf('day')
    .toISOString();
  const { sources } = useAccount();
  const [activeSourceName, setActiveSourceName] = useState(null);

  const [getDashboardData, { loading, data }] = useLazyQuery(
    GET_DASHBOARD_DATA
  );

  useEffect(() => {
    if (sources && sources.length > 0) {
      const hasStatsSources = sources.filter(
        source => source.hasStats === true
      );
      setActiveSourceName(hasStatsSources[0].filterName);
    }
  }, [sources]);

  useEffect(() => {
    const loadDashboardData = async () => {
      await getDashboardData({
        variables: {
          thisWeekFilter: {
            source: activeSourceName,
            from: thisWeekFrom,
            to: thisWeekTo
          },
          lastWeekFilter: {
            source: activeSourceName,
            from: lastWeekFrom,
            to: lastWeekTo
          }
        }
      });
    };

    if (sources && sources.length > 0 && activeSourceName) {
      loadDashboardData();
    }
  }, [
    activeSourceName,
    getDashboardData,
    lastWeekFrom,
    lastWeekTo,
    sources,
    thisWeekFrom,
    thisWeekTo
  ]);

  const changeActiveSourceName = sourceName => {
    setActiveSourceName(sourceName);
  };

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const statsData = data ? calculateStatisticsData(data) : null;
  const graphData = statsData
    ? [statsData.graphAll, statsData.graphChecked]
    : null;
  const statsSources = sources ? sources.filter(source => source.hasStats) : [];

  return (
    <>
      <DashboardHeader
        statsData={statsData}
        isLoading={loading}
        statsSources={statsSources}
        activeSourceName={activeSourceName || ''}
        changeActiveSourceName={changeActiveSourceName}
      />
      <Container className="mt--7" fluid>
        {graphData && (
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <StatsChart
                title={t('general.cases')}
                subtitle={t('general.overview')}
                graphData={graphData}
              />
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
}

export default Dashboard;
