import React, { useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from 'reactstrap';
import { forgotPassword } from 'utils/auth';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function ForgotPassword(props) {
  const { t } = useTranslation();
  const defaultValues = {
    username: ''
  };

  const [values, setValues] = useState(defaultValues);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const resetValues = () => {
    setValues(defaultValues);
  };

  const handleOnSubmit = async e => {
    e.preventDefault();
    const { username } = values;
    const { history } = props;

    setIsLoading(true);
    try {
      await forgotPassword(username);
      setIsLoading(false);
      toast.success(t('forgot_password.check_your_email'));
      history.push({
        pathname: '/new-password',
        search: `?username=${username}`
      });
    } catch (error) {
      setIsLoading(false);
      toast.error(
        t('forgot_password.failed_to_send_recovery_email', { error })
      );
      resetValues();
    }
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>{t('forgot_password.enter_username')}</small>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-circle-08" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    id="username"
                    name="username"
                    placeholder={t('forgot_password.username_placeholder')}
                    type="text"
                    value={values.username}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button
                  className="my-4"
                  color="primary"
                  type="submit"
                  onClick={handleOnSubmit}
                  disabled={isLoading}
                >
                  {isLoading
                    ? t('forgot_password.sending_email')
                    : t('forgot_password.send_recovery_email')}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col className="text-right">
            <Link
              to={{
                pathname: `/register`
              }}
            >
              <small>{t('forgot_password.create_new_account')}</small>
            </Link>
          </Col>
        </Row>
      </Col>
    </>
  );
}

ForgotPassword.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default ForgotPassword;
