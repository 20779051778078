import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import pathnameToBreadcrumbs from 'utils/breadcrumbs';
import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';

const GET_CASE_NAME = gql`
  query getCaseName($id: ID!) {
    getAlbum(id: $id) {
      id
      name
    }
  }
`;

function getCaseIdFromBreadcrumb(breadcrumb) {
  const { path } = breadcrumb;
  if (!path) {
    return null;
  }
  const tokens = breadcrumb.path.split('/');
  if (tokens.length === 3) {
    return tokens[2];
  }
  return null;
}

function BreadCrumbs() {
  const { t } = useTranslation();
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState(null);

  const [getCaseName, { data: getCaseNameData }] = useLazyQuery(GET_CASE_NAME, {
    fetchPolicy: 'cache-only'
  });

  useEffect(() => {
    const addCaseName = async (bcs, bci, caseId) => {
      await getCaseName({ variables: { id: caseId } });
      const caseName =
        getCaseNameData &&
        getCaseNameData.getAlbum &&
        getCaseNameData.getAlbum.name
          ? getCaseNameData.getAlbum.name
          : null;
      if (caseName) {
        const newBcs = [...bcs];
        newBcs[bci].name = caseName;
        setBreadcrumbs(newBcs);
      }
    };

    // calculate basic breadcrumbs
    const bcs = pathnameToBreadcrumbs(location.pathname);
    setBreadcrumbs(bcs);

    // find caseDetail breadcrumb if exists
    const caseDetailPathRegexp = /\/cases\/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/;
    const caseDetailBreadcrumbIndex = bcs.findIndex(bc =>
      bc.path.match(caseDetailPathRegexp)
    );

    // if casedetail breadcrumb exists, then apply caseName
    if (caseDetailBreadcrumbIndex !== -1) {
      const caseId = getCaseIdFromBreadcrumb(bcs[caseDetailBreadcrumbIndex]);
      addCaseName(bcs, caseDetailBreadcrumbIndex, caseId);
    } else {
      setBreadcrumbs(bcs);
    }
  }, [getCaseName, getCaseNameData, location]);

  return (
    <>
      <div className="col-7 col-lg-6 pl-0">
        <nav
          className="d-none d-md-inline-block ml-md-4"
          aria-label="breadcrumb"
        >
          <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
            {breadcrumbs &&
              breadcrumbs.map(breadcrumb => (
                <li key={breadcrumb.path} className="breadcrumb-item">
                  <Link to={breadcrumb.path}>
                    {breadcrumb.faIcon && <i className={breadcrumb.faIcon} />}
                    {breadcrumb.name &&
                      t(`breadcrumbs.${breadcrumb.name}`, {
                        defaultValue: breadcrumb.name
                      })}
                  </Link>
                </li>
              ))}
          </ol>
        </nav>
      </div>
    </>
  );
}

export default BreadCrumbs;
