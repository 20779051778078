import jwt from 'jsonwebtoken';

function isValidJWT(token) {
  if (!token) {
    return false;
  }

  const decodedContent = jwt.decode(token);

  // time validity
  const nowUnix = new Date().getTime();
  const { exp } = decodedContent;
  if (nowUnix > exp * 1000) {
    return false;
  }

  return true;
}

export default isValidJWT;
