import React from 'react';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

const languages = [
  {
    value: 'en',
    label: 'EN'
  },
  {
    value: 'cs',
    label: 'CZ'
  },
  {
    value: 'sk',
    label: 'SK'
  },
  {
    value: 'pt',
    label: 'PT'
  },
  {
    value: 'debug',
    label: 'DEBUG'
  }
];

function availableLanguages() {
  if (process.env.NODE_ENV === 'production') {
    return languages.filter(language => language.value !== 'debug');
  }
  return languages;
}

function LanguageSelector() {
  const { i18n } = useTranslation();

  const changeLanguage = language => {
    i18n.changeLanguage(language);
  };

  return (
    <UncontrolledDropdown nav>
      <DropdownToggle nav className="nav-link-icon">
        {i18n.language}
      </DropdownToggle>
      <DropdownMenu
        aria-labelledby="navbar-default_dropdown_1"
        className="dropdown-menu-arrow"
        right
      >
        {availableLanguages().map(({ value, label }) => (
          <DropdownItem key={value} onClick={() => changeLanguage(value)}>
            <span
              className={classnames({
                'font-weight-900': value === i18n.language
              })}
            >
              {label}
            </span>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export default LanguageSelector;
