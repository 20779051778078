import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import MFASettings from 'components/MFASettings';

function Settings() {
  const { t } = useTranslation();

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <Row className="mt--5">
            <Col>
              <h1 className="text-white">{t('general.settings')}</h1>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <MFASettings />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Settings;
