import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import StatsCard from 'components/StatsCard';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function DashboardHeader({
  statsData,
  isLoading,
  statsSources,
  activeSourceName,
  changeActiveSourceName
}) {
  const { t } = useTranslation();
  const thisWeekCases = statsData ? statsData.thisWeek.cases : null;
  const lastWeekCases = statsData ? statsData.lastWeek.cases : null;

  const thisWeekDailyAverageCases = statsData
    ? statsData.thisWeek.dailyAverageCases
    : null;
  const lastWeekDailyAverageCases = statsData
    ? statsData.lastWeek.dailyAverageCases
    : null;

  const thisWeekNotOkCases = statsData ? statsData.thisWeek.notOkCases : null;
  const lastWeekNotOkCases = statsData ? statsData.lastWeek.notOkCases : null;

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <Row className="mt--5 mb-4">
            <Col>
              {statsSources.map(source => (
                <Button
                  color={
                    activeSourceName === source.filterName
                      ? 'default'
                      : 'secondary'
                  }
                  type="button"
                  onClick={() => changeActiveSourceName(source.filterName)}
                  key={source.filterName}
                >
                  {source.name}
                </Button>
              ))}
            </Col>
          </Row>
          <div className="header-body">
            <Row>
              <Col lg="6" xl="4">
                <StatsCard
                  title={t('dashboard.cases_this_week')}
                  value={thisWeekCases}
                  previousValue={lastWeekCases}
                  iconClassName="fas fa-plus"
                  iconBackgroundColor="bg-success"
                  isLoading={statsData === null || isLoading}
                />
              </Col>
              <Col lg="6" xl="4">
                <StatsCard
                  title={t('dashboard.daily_average_cases')}
                  value={Math.round(thisWeekDailyAverageCases)}
                  previousValue={Math.round(lastWeekDailyAverageCases)}
                  iconClassName="far fa-chart-bar"
                  iconBackgroundColor="bg-warning"
                  isLoading={statsData === null || isLoading}
                />
              </Col>
              <Col lg="6" xl="4">
                <StatsCard
                  title={t('dashboard.not_ok_cases')}
                  value={thisWeekNotOkCases}
                  previousValue={lastWeekNotOkCases}
                  iconClassName="fas fa-times"
                  iconBackgroundColor="bg-danger"
                  isLoading={statsData === null || isLoading}
                />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

DashboardHeader.propTypes = {
  isLoading: PropTypes.bool,
  activeSource: PropTypes.string,
  statsSources: PropTypes.arrayOf(
    PropTypes.shape({
      filterName: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  changeActiveSourceName: PropTypes.func.isRequired
};

DashboardHeader.defaultProps = {
  isLoading: false,
  activeSource: null
};

export default DashboardHeader;
